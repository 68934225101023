import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import MastercardLogo from '@images/icons/cardTypes/mastercard-card-type.svg?react'
import MastercardPureLogo from '@images/icons/cardTypes/mastercard-pure-logo.svg?react'
import UnionpayLogo from '@images/icons/cardTypes/unionpay-card-type.svg?react'
import UnionpayPureLogo from '@images/icons/cardTypes/unionpay-pure-icon.svg?react'
import VisaLogo from '@images/icons/cardTypes/visa-card-type.svg?react'
import VisaPureLogo from '@images/icons/cardTypes/visa-pure-icon.svg?react'
import PhoneIcon from '@images/icons/card-order-phone-icon.svg?react'
import PhysicalCardIcon from '@images/physical-card-without-brand.svg?react'
import VirtualCardIcon from '@images/virtual-card-without-brand.svg?react'
import SuccessIcon from '@images/icons/success-check.svg?react'
import cn from 'classnames'

import {
  paths,
  useCardsSupportedQry,
  useChange3dsPhoneMutation,
  useGetAllCardsQuery,
  useGetMerchantQuery,
  useGetProfileQuery,
  useIssuePrepaidCardMutation
} from 'mmfintech-backend-api'
import {
  GlobalContext,
  OtpContext,
  checkFinalValue,
  fixPhoneNumber,
  isValidArray,
  isValidObject,
  isValidString,
  tr,
  translateError,
  useFormValues
} from 'mmfintech-commons'
import { ErrorDisplay, Spinner } from 'mmfintech-portal-commons'

import { CardOrderForm } from './CardOrderForm'
import './cardOrderModal.scss'

// import SuccessImage from '@images/deposit-success.png'
import { CoreCheckbox, CoreInput, CoreButton, CoreSteps } from '@components'
import ReactTooltip from 'react-tooltip'
import { MerchantEntityTypeEnum } from 'mmfintech-commons-types'

interface CardOrderModalProps {
  onClose?: () => void
  currentStep?: number
  selectedCardType?: 'physical' | 'virtual'
  issueCard?: boolean
  passedData?: any
  error?: any
  virtualCardBrand?: string
  physicalCardBrand?: string
  cardPhysicalCurrency?: string
  cardVirtualCurrency?: string
}

const findCardBrand = (cardBrand: string, logoOnly?: boolean) => {
  switch (cardBrand) {
    case 'MASTERCARD':
      return logoOnly ? <MastercardPureLogo /> : <MastercardLogo />
    case 'VISA':
      return logoOnly ? <VisaPureLogo /> : <VisaLogo />
    case 'UNIONPAY':
      return logoOnly ? <UnionpayPureLogo /> : <UnionpayLogo />
    default:
      break
  }
}

export const CardOrderModal = ({
  onClose,
  currentStep,
  selectedCardType,
  issueCard,
  passedData,
  error,
  virtualCardBrand,
  physicalCardBrand,
  cardPhysicalCurrency,
  cardVirtualCurrency
}: CardOrderModalProps) => {
  const history = useHistory()
  const [step, setStep] = useState<number>(currentStep || 1)
  const [cardType, setCardType] = useState<'physical' | 'virtual'>(selectedCardType)
  const [phoneNumber, setPhoneNumber] = useState<string>(null)
  const [issuingError, setIssuingError] = useState(null)
  const [selectedPhysicalBrand, setSelectedPhysicalBrand] = useState(physicalCardBrand)
  const [selectedVirtualBrand, setSelectedVirtualBrand] = useState(virtualCardBrand)
  const [enablePhysicalCardBrandOptions, setEnablePhysicalCardBrandOptions] = useState<boolean>(null)
  const [enableVirtualCardBrandOptions, setEnableVirtualCardBrandOptions] = useState<boolean>(null)
  const [disablePhysicalCard, setDisablePhysicalCard] = useState<boolean>(false)
  const [disableVirtualCard, setDisableVirtualCard] = useState<boolean>(false)
  const [selectedPhysicalCardCurrency, setSelectedPhysicalCardCurrency] = useState(cardPhysicalCurrency)
  const [selectedVirtualCardCurrency, setSelectedVirtualCardCurrency] = useState(cardVirtualCurrency)

  const { setOtpOnSuccess } = useContext(OtpContext)
  const { modalShow, modalHide } = useContext(GlobalContext)

  const { data: merchant } = useGetMerchantQuery()
  const { entityType } = merchant || {}

  const formValues = useFormValues({
    countryCode: { required: true },
    city: { required: true, validation: 'alphaNumeric+' },
    street: { required: true, validation: 'safe-string' },
    streetNumber: { required: true, validation: 'safe-string' },
    postalCode: { required: true, validation: 'safe-string' },
    companyName: { required: true, validation: 'safe-string' },
    firstName: { required: true, validation: 'safe-string' },
    phoneNumber: { required: true, validation: 'phone' },
    lastName: { required: true, validation: 'safe-string' }
  })

  const [orderCard, { isLoading, error: cardIssueError, reset }] = useIssuePrepaidCardMutation()
  const [changePhone, { error: changePhoneError, isLoading: changePhoneLoading }] = useChange3dsPhoneMutation()
  const { data: activeCards, isLoading: activeCardsLoading, error: activeCardsError } = useGetAllCardsQuery(null)
  // const {
  //   data: supportedCardCurrencies,
  //   isLoading: supportedCardCurrenciesLoading,
  //   error: supportedCardCurrenciesError
  // } = useGetSupportedCardCurrenciesQuery(null)

  const {
    findCurrencies,
    supportedCards,
    supportedPhysical,
    supportedVirtual,
    supportedCardsError,
    supportedCardsLoading
  } = useCardsSupportedQry()

  const {
    hasValidatedPhone,
    phoneChecked,
    twoFactorPhone,
    // phoneCheckLoading,
    refetch: recheckPhone
  } = useGetProfileQuery(null, {
    selectFromResult: ({ data, isSuccess, isLoading }) => {
      return {
        hasValidatedPhone: !!data?.phone3dsVerified,
        twoFactorPhone: data?.twoFactorPhone,
        phoneChecked: isSuccess,
        phoneCheckLoading: isLoading
      }
    }
  })

  const handlePhoneChange = async () => {
    setOtpOnSuccess(() => async (response: any) => {
      if (response) {
        setTimeout(() => {
          modalShow({
            header: tr('FRONTEND.DASHBOARD.ORDER_CARDS.TITLE', 'Order a card'),
            content: (
              <CardOrderModal
                onClose={modalHide}
                currentStep={2}
                selectedCardType={'virtual'}
                virtualCardBrand={selectedVirtualBrand}
                physicalCardBrand={selectedPhysicalBrand}
                cardPhysicalCurrency={selectedPhysicalCardCurrency}
                cardVirtualCurrency={selectedVirtualCardCurrency}
              />
            )
          })
        }, 0)
      }
    })
    try {
      const state = checkFinalValue(phoneNumber, { validation: 'phone', required: true })

      if (state.valid) {
        const result = await changePhone({ phone: fixPhoneNumber(phoneNumber) }).unwrap()
        if (result && !result.challenge?.challengeId) {
          await recheckPhone().unwrap()
          modalShow({
            header: tr('FRONTEND.DASHBOARD.ORDER_CARDS.TITLE', 'Order a card'),
            content: (
              <CardOrderModal
                onClose={modalHide}
                currentStep={2}
                selectedCardType={'virtual'}
                virtualCardBrand={selectedVirtualBrand}
                physicalCardBrand={selectedPhysicalBrand}
                cardPhysicalCurrency={selectedPhysicalCardCurrency}
                cardVirtualCurrency={selectedVirtualCardCurrency}
              />
            )
          })
        }
      } else {
        setIssuingError(translateError(state))
      }
    } catch (error) {
      return error
    }
  }

  const handleSubmit = async () => {
    setOtpOnSuccess(() => (response: any) => {
      if (response && response.externalCardId) {
        setTimeout(() => {
          modalShow({
            header: tr('FRONTEND.DASHBOARD.ORDER_CARDS.TITLE', 'Order a card'),
            content: (
              <CardOrderModal
                onClose={modalHide}
                currentStep={3}
                selectedCardType={cardType}
                virtualCardBrand={selectedVirtualBrand}
                physicalCardBrand={selectedPhysicalBrand}
                cardPhysicalCurrency={selectedPhysicalCardCurrency}
                cardVirtualCurrency={selectedVirtualCardCurrency}
              />
            )
          })
        }, 0)
      }

      if (response && !response.externalCardId) {
        setTimeout(() => {
          modalShow({
            header: tr('FRONTEND.DASHBOARD.ORDER_CARDS.TITLE', 'Order a card'),
            content: (
              <CardOrderModal
                onClose={modalHide}
                currentStep={1}
                error={tr('FRONTEND.DASHBOARD.ORDER_CARDS.GENERAL_ERROR', 'Issuing failed')}
                virtualCardBrand={selectedVirtualBrand}
                physicalCardBrand={selectedPhysicalBrand}
                cardPhysicalCurrency={selectedPhysicalCardCurrency}
                cardVirtualCurrency={selectedVirtualCardCurrency}
              />
            )
          })
        }, 0)
      }
    })
    try {
      const data = {
        isVirtual: cardType === 'virtual',
        currencyCode: (cardType === 'virtual' ? selectedVirtualCardCurrency : selectedPhysicalCardCurrency) || 'EUR',
        cardBrand: cardType === 'virtual' ? selectedVirtualBrand : selectedPhysicalBrand,
        ...(entityType === MerchantEntityTypeEnum.COMPANY
          ? {
              companyCardDetails: {
                companyName: formValues.getValue('companyName'),
                firstName: formValues.getValue('firstName'),
                lastName: formValues.getValue('lastName')
              }
            }
          : null),
        ...(cardType === 'physical'
          ? {
              address: {
                country: formValues.getValue('countryCode'),
                streetName: formValues.getValue('street'),
                streetNumber: formValues.getValue('streetNumber'),
                zipCode: formValues.getValue('postalCode'),
                city: formValues.getValue('city')
              }
            }
          : null)
      }

      if (
        (cardType === 'physical' || entityType === MerchantEntityTypeEnum.COMPANY) &&
        phoneChecked &&
        !hasValidatedPhone
      ) {
        setOtpOnSuccess(() => (response: any) => {
          if (response) {
            if (response && response.externalCardId) {
              setTimeout(() => {
                modalShow({
                  header: tr('FRONTEND.DASHBOARD.ORDER_CARDS.TITLE', 'Order a card'),
                  content: (
                    <CardOrderModal
                      onClose={modalHide}
                      currentStep={3}
                      selectedCardType={cardType}
                      virtualCardBrand={selectedVirtualBrand}
                      physicalCardBrand={selectedPhysicalBrand}
                      cardPhysicalCurrency={selectedPhysicalCardCurrency}
                      cardVirtualCurrency={selectedVirtualCardCurrency}
                    />
                  )
                })
              }, 0)
            } else {
              setTimeout(() => {
                modalShow({
                  header: tr('FRONTEND.DASHBOARD.ORDER_CARDS.TITLE', 'Order a card'),
                  content: (
                    <CardOrderModal
                      onClose={modalHide}
                      currentStep={2}
                      selectedCardType={cardType}
                      issueCard={true}
                      passedData={formValues.prepare()}
                      virtualCardBrand={selectedVirtualBrand}
                      physicalCardBrand={selectedPhysicalBrand}
                      cardPhysicalCurrency={selectedPhysicalCardCurrency}
                      cardVirtualCurrency={selectedVirtualCardCurrency}
                    />
                  )
                })
              }, 0)
            }
          }
        })
        const state = checkFinalValue(formValues.getValue('phoneNumber'), { validation: 'phone', required: true })

        if (state.valid) {
          const changeResult = await changePhone({ phone: fixPhoneNumber(formValues.getValue('phoneNumber')) }).unwrap()
          if (changeResult.challenge) {
            return changeResult
          }
        } else {
          return formValues.setError('phoneNumber', translateError(state))
        }
      }

      const result = await orderCard(data).unwrap()
      if (step !== 3 && result && result.externalCardId) {
        setStep(3)
      }
      if (result && !result.externalCardId) {
        setIssuingError(tr('FRONTEND.DASHBOARD.ORDER_CARDS.GENERAL_ERROR', 'Issuing failed'))
      }
      return result
    } catch (err) {
      return err
    }
  }

  useEffect(() => {
    if (step == 2 && cardType == 'virtual' && entityType == MerchantEntityTypeEnum.INDIVIDUAL && hasValidatedPhone) {
      void handleSubmit()
    }
    if (step == 2 && (cardType == 'physical' || entityType == MerchantEntityTypeEnum.COMPANY) && issueCard) {
      if (formValues && passedData) {
        Object.entries(passedData).forEach(el => {
          const [key, value] = el
          formValues.setValue(key, value)
        })
        // if (passedData.address) {
        //   Object.entries(passedData.address).forEach(el => {
        //     const [key, value] = el
        //     formValues.setValue(key, value)
        //   })
        // }
      }

      void handleSubmit()
    }
  }, [step, hasValidatedPhone, issueCard])

  useEffect(() => {
    if (isValidString(twoFactorPhone)) {
      setPhoneNumber(twoFactorPhone)
    }
  }, [phoneChecked])

  useEffect(() => {
    if (error) {
      setIssuingError(error)
    }
  }, [error])

  useEffect(() => {
    if (isValidArray(supportedPhysical) && !isValidString(selectedPhysicalBrand)) {
      setSelectedPhysicalBrand(supportedPhysical[0])
    }
    setEnablePhysicalCardBrandOptions(supportedPhysical?.length > 1)

    if (isValidArray(supportedVirtual) && !isValidString(selectedVirtualBrand)) {
      setSelectedVirtualBrand(supportedVirtual[0])
    }
    setEnableVirtualCardBrandOptions(supportedVirtual?.length > 1)
  }, [selectedPhysicalCardCurrency, selectedVirtualCardCurrency, supportedCards, selectedCardType])

  useEffect(() => {
    if (
      (isValidArray(activeCards) && activeCards.find(card => card.isVirtual != true)) ||
      !isValidArray(supportedPhysical)
    ) {
      setDisablePhysicalCard(true)
    } else {
      setDisablePhysicalCard(false)
    }

    if (!isValidArray(supportedVirtual)) {
      setDisableVirtualCard(true)
    } else {
      setDisableVirtualCard(false)
    }
  }, [activeCards, supportedCards, selectedPhysicalCardCurrency, selectedVirtualCardCurrency])

  useEffect(() => {
    if (selectedPhysicalBrand && cardType === 'physical' && isValidObject(supportedCards)) {
      setSelectedPhysicalCardCurrency(findCurrencies(cardType?.toLocaleUpperCase(), selectedPhysicalBrand)?.[0])
    }
    if (selectedVirtualBrand && cardType === 'virtual' && isValidObject(supportedCards)) {
      setSelectedVirtualCardCurrency(findCurrencies(cardType?.toLocaleUpperCase(), selectedVirtualBrand)?.[0])
    }
  }, [selectedVirtualBrand, selectedPhysicalBrand, cardType, supportedCards])

  return (
    <div className='card-order-modal-wrapper'>
      {activeCardsLoading || supportedCardsLoading ? (
        <Spinner />
      ) : (
        <>
          {step === 1 && (
            <div className='card-order-type-selection'>
              <ErrorDisplay error={[issuingError, supportedCardsError]} />

              <div
                className={cn('card-order-flag-wrapper', {
                  open: cardType === 'physical' && enablePhysicalCardBrandOptions,
                  'card-unavailable': disablePhysicalCard
                })}
                data-for={`card-order-card`}
                data-tip={
                  isValidArray(activeCards) && activeCards.find(card => card.isVirtual == false)
                    ? tr('FRONTEND.CARD_ORDER.TOOLTIP_UNAVAILABLE_PHYSICAL_CARD', 'You already have physical card.')
                    : !isValidArray(supportedPhysical)
                    ? tr(
                        'FRONTEND.CARD_ORDER.TOOLTIP_UNAVAILABLE_PHYSICAL_CARD',
                        'This currency do not support physical card.'
                      )
                    : null
                }>
                <div
                  className={cn('card-selection-background', {
                    'card-background-selected': cardType === 'physical'
                  })}></div>
                <div
                  className={cn('card-order-card-type-container', {
                    types: cardType === 'physical' && enablePhysicalCardBrandOptions,
                    'card-selected': cardType === 'physical'
                  })}
                  onClick={() => {
                    !disablePhysicalCard && setCardType('physical')
                  }}>
                  <div className='card-order-check-icon'>
                    <CoreCheckbox
                      value='physical'
                      checked={cardType === 'physical'}
                      type='check'
                      disabled={disablePhysicalCard}
                    />
                  </div>
                  <div className='card-order-card-icon'>
                    <PhysicalCardIcon />
                    <div className='card-brand-icon '>{findCardBrand(selectedPhysicalBrand, true)}</div>
                  </div>
                  <div className='card-order-card-content'>
                    <div className='card-order-card-title'>
                      {tr('FRONTEND.DASHBOARD.CARDS_ORDER.PHYSICAL.TITLE', 'Physical card')}
                    </div>
                    <div className='card-order-card-text'>
                      {tr(
                        'FRONTEND.DASHBOARD.CARDS_ORDER.PHYSICAL.TEXT_WITH_CURRENCY',
                        `A contactless debit card will be delivered to you`
                      )}
                      {/* . Your card balance will be {{CURRENCY}} with a new account.` */}
                      {/* ).replace('{{CURRENCY}}', selectedPhysicalCardCurrency || 'GBP')} */}
                    </div>
                  </div>
                </div>
                {enablePhysicalCardBrandOptions && (
                  <div className={cn('card-order-choose-card-brand', { open: cardType === 'physical' })}>
                    {isValidArray(supportedPhysical) &&
                      supportedPhysical.map(brand => {
                        return (
                          <div
                            onClick={() => setSelectedPhysicalBrand(brand)}
                            className={`card-order-brand-wrapper ${brand === selectedPhysicalBrand ? 'selected' : ''}`}>
                            <div>{findCardBrand(brand as string)}</div>
                            <span className='card-order-chose-brand-label'>{brand.toLowerCase()}</span>
                            <CoreCheckbox
                              className='card-order-checkbox'
                              value={brand as string}
                              checked={selectedPhysicalBrand === brand}
                              type='circle'
                            />
                          </div>
                        )
                      })}
                  </div>
                )}
              </div>

              <div
                className={cn('card-order-flag-wrapper', {
                  open: cardType === 'virtual' && enableVirtualCardBrandOptions,
                  'card-unavailable': disableVirtualCard
                })}
                data-for={`card-order-virtual-card`}
                data-tip={
                  !isValidArray(supportedVirtual)
                    ? tr(
                        'FRONTEND.CARD_ORDER.TOOLTIP_UNAVAILABLE_VIRTUAL_CARD',
                        'This currency do not support virtual card.'
                      )
                    : tr('FRONTEND.CARD_ORDER.TOOLTIP_UNAVAILABLE_VIRTUAL_CARD', 'You already have virtual card.')
                }>
                <div
                  className={cn('card-selection-background', {
                    'card-background-selected': cardType === 'virtual'
                  })}></div>
                <div
                  className={cn('card-order-card-type-container', {
                    types: cardType === 'virtual' && enableVirtualCardBrandOptions,
                    'card-selected': cardType === 'virtual'
                  })}
                  onClick={() => {
                    !disableVirtualCard && setCardType('virtual')
                  }}>
                  <div className='card-order-check-icon'>
                    <CoreCheckbox
                      value='virtual'
                      checked={cardType === 'virtual'}
                      type='check'
                      disabled={disableVirtualCard}
                    />
                  </div>
                  <div className='card-order-card-icon'>
                    <VirtualCardIcon />
                    <div className='card-brand-icon '>{findCardBrand(selectedVirtualBrand, true)}</div>
                  </div>
                  <div className='card-order-card-content'>
                    <div className='card-order-card-title'>
                      {tr('FRONTEND.DASHBOARD.CARDS_ORDER.VIRTUAL.TITLE', 'Virtual card')}
                    </div>
                    <div className='card-order-card-text'>
                      {tr(
                        'FRONTEND.DASHBOARD.CARDS_ORDER.VIRTUAL.TEXT_WITH_CURRENCY',
                        'Get virtual card and manage your online payments.'
                      )}

                      {/* Your card balance will be {{CURRENCY}} with a new account.'
                      ).replace('{{CURRENCY}}', selectedVirtualCardCurrency || 'GBP')} */}
                    </div>
                  </div>
                </div>
                {enableVirtualCardBrandOptions && (
                  <div className={cn('card-order-choose-card-brand', { open: cardType === 'virtual' })}>
                    {isValidArray(supportedVirtual) &&
                      supportedVirtual.map(brand => {
                        return (
                          <div
                            onClick={() => setSelectedVirtualBrand(brand)}
                            className={`card-order-brand-wrapper ${brand === selectedVirtualBrand ? 'selected' : ''}`}>
                            <div>{findCardBrand(brand as string)}</div>
                            <span>{brand.toLowerCase()}</span>
                            <CoreCheckbox
                              className='card-order-checkbox'
                              value={brand as string}
                              checked={selectedVirtualBrand === brand}
                              type='circle'
                            />
                          </div>
                        )
                      })}
                  </div>
                )}
              </div>
              <ReactTooltip
                id={`card-order-card`}
                textColor='black'
                backgroundColor='white'
                effect='solid'
                className='custom-tool-tip-component'
                padding='8px 6px'
                place={'right'}></ReactTooltip>
            </div>
          )}
          {step === 2 && (cardType === 'physical' || entityType === MerchantEntityTypeEnum.COMPANY) && (
            <CardOrderForm
              formValues={formValues}
              cardIssueLoading={isLoading}
              changePhoneError={changePhoneError || issuingError}
              cardType={cardType}
              entityType={entityType}
            />
          )}
          {step === 3 && (
            <div className='card-order-success-wrapper'>
              <div className='card-order-success-icon'>
                <SuccessIcon />
              </div>
              <div>
                <div className='card-order-success-title'>
                  {tr(
                    'FRONTEND.DASHBOARD.CARDS_ORDER.SUCCESS.TITLE',
                    'You have successfully ordered The Kingdom Bank Card!'
                  )}
                </div>
                <div className='card-order-success-subtitle'>
                  {selectedCardType == 'physical'
                    ? tr(
                        'FRONTEND.DASHBOARD.CARDS_ORDER.SUCCESS.SUBTITLE',
                        'Your card will be ready to be delivered within 1-2 weeks.'
                      )
                    : tr(
                        'FRONTEND.DASHBOARD.CARDS_ORDER.SUCCESS.VIRTUAL',
                        'Your card will be available in the Cards menu.'
                      )}
                </div>
              </div>
            </div>
          )}
        </>
      )}
      <div className='card-order-stepper'>
        <CoreSteps step={step} maxStep={3} />
      </div>
      {step == 2 &&
        phoneChecked &&
        !hasValidatedPhone &&
        entityType === MerchantEntityTypeEnum.INDIVIDUAL &&
        cardType !== 'physical' && (
          <div className='card-order-phone-section'>
            <div className='card-order-phone-icon'>
              <PhoneIcon />
            </div>
            <div className='card-order-phone-instructions'>
              {tr(
                'FRONTEND.DASHBOARD.CARDS_ORDER.PHONE_INSTRUCTIONS',
                "Please enter your phone number. We'll send you a code via SMS"
              )}
            </div>
            <CoreInput
              label={tr('FRONTEND.DASHBOARD.CARDS_ORDER.LABEL.PHONE_NUMBER', 'Phone number')}
              type='phone'
              onChange={(_: any, value: string) => {
                setPhoneNumber(value)
                setIssuingError(null)
              }}
              value={phoneNumber}
            />
            <ErrorDisplay error={issuingError} />
          </div>
        )}
      <ErrorDisplay error={[activeCardsError, cardIssueError, changePhoneError]} />

      <div className='card-order-modal-buttons-wrapper'>
        {step > 1 && step !== 3 && !isLoading ? (
          <CoreButton
            size='large'
            variation='tertiary'
            text={tr('FRONTEND.DASHBOARD.CARDS_ORDER.BACK_BUTTON', 'Back')}
            className='card-order-modal-back-button'
            isLoading={changePhoneLoading}
            onClick={async () => {
              reset()
              if (step > 1) {
                setStep(step - 1)
              }
            }}
          />
        ) : null}
        <CoreButton
          fullWidth
          size='large'
          disabled={(step === 1 && !cardType) || isLoading || cardIssueError}
          text={
            step === 1
              ? tr('FRONTEND.DASHBOARD.CARDS_ORDER.CONTINUE_BUTTON', 'Continue')
              : step === 2
              ? tr('FRONTEND.DASHBOARD.CARDS_ORDER.COMPLETE_BUTTON', 'Accept and complete order')
              : tr('FRONTEND.DASHBOARD.CARDS_ORDER.FINISH_BUTTON', 'Continue to Overview')
          }
          isLoading={isLoading || changePhoneLoading}
          onClick={async () => {
            if (step === 3) {
              onClose && onClose()
              return history.push(paths.dashboard())
            }
            if (step === 2) {
              if (cardType === 'physical' || entityType === MerchantEntityTypeEnum.COMPANY) {
                return await handleSubmit()
              } else {
                return await handlePhoneChange()
              }
            }
            if (step !== 3) {
              setStep(step => step + 1)
            }
          }}
        />
      </div>
    </div>
  )
}
