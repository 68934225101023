import {
  isOwnerOrAdministrator,
  selectCurrentUserRole,
  useAppSelector,
  useGetMerchantQuery,
  useGetReportQuery
} from 'mmfintech-backend-api'

import { ReportActionButtons, ReportActivitiesButton, ReportSubmitButton } from '@views/expenseManagement/components'

import { CustomerRoleEnum, ReportStateEnum } from 'mmfintech-commons-types'

export const ReportHeaderButtons = ({ reportId }) => {
  const { data: merchant } = useGetMerchantQuery(null)
  const { merchantId: userId } = merchant || {}

  const customerRole = useAppSelector(selectCurrentUserRole)

  const { data: report } = useGetReportQuery({ reportId }, { skip: !reportId })

  switch (report?.state) {
    case ReportStateEnum.NEW:
    case ReportStateEnum.EDIT:
      return userId == report?.merchantId ? (
        <>
          <ReportSubmitButton report={report} />
          {report?.state === ReportStateEnum.EDIT && <ReportActivitiesButton reportId={reportId} />}
        </>
      ) : null

    case ReportStateEnum.WAITING_APPROVAL:
      return customerRole === CustomerRoleEnum.APPROVER ? <ReportActionButtons reportId={reportId} /> : null

    case ReportStateEnum.WAITING_FINANCE_APPROVAL:
      return isOwnerOrAdministrator(customerRole) ? <ReportActionButtons reportId={reportId} /> : null

    default:
      return <ReportActivitiesButton reportId={reportId} />
  }
}
