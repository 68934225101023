import { useContext } from 'react'

import { breakpoints } from '@constants'
import { useMatchMedia } from '@hooks'
import moment from 'moment'

import {
  extractCounterpartyName,
  extractTransactionCustomerName,
  formatTransactionDescription,
  getTransactionStatusName,
  useTransactionColumns
} from 'mmfintech-backend-api'
import {
  GlobalContext,
  cutString,
  fetchLastChunk,
  formatFloat,
  getCurrencyDecimals,
  isValidString,
  tr
} from 'mmfintech-commons'
import { TransactionStatusEnum, TransactionViewPortal } from 'mmfintech-commons-types'
import { DataTable } from 'mmfintech-portal-commons'

import TransactionTypeWithIcon from './TransactionTypeWithIcon'
import TransactionDetails from '../TransactionDetails'
import { CoreStatusView } from '@components'
import settings from '@settings'

// import PreviewIcon from '@images/icons/preview-icon.svg?react'

import '@views/transactions/styled/transactionsTable.scss'
import './styled/transactionTables.scss'

type TTransactionsTableProps = {
  columns?: ReturnType<typeof useTransactionColumns>
  transactions: TransactionViewPortal[]
}

function TransactionsTable({ columns, transactions }: TTransactionsTableProps) {
  const isSmall = useMatchMedia({ breakpoint: 800, prefix: 'min' })
  const isMobile = useMatchMedia({ breakpoint: breakpoints.md, prefix: 'min' })
  const isLarge = useMatchMedia({ breakpoint: breakpoints['2xl'], prefix: 'min' })
  const defaultColumns = useTransactionColumns({
    cookieDomain: settings.cookieDomain,
    enableCustomerEmail: false,
    enableFailReason: true
  })
  const currentColumns = columns || defaultColumns
  const { modalShow } = useContext(GlobalContext)

  const handleDetailsClick = (transactionId: number) => {
    modalShow({
      header: tr('FRONTEND.TRANSACTIONS.DETAILS.TITLE', 'Transaction details'),
      content: <TransactionDetails isModal={true} transactionId={transactionId} />,
      options: {
        size: 'auto'
      }
    })
  }

  return (
    <div className='table-container transaction-table-container'>
      <DataTable.Table>
        <DataTable.Head>
          <DataTable.Row>
            {currentColumns.isVisible('id') && (
              <DataTable.HeadCell className='no-border table-header-cell'>
                {tr('FRONTEND.TRANSACTIONS.COLUMNS.ID', 'Transaction ID')}
              </DataTable.HeadCell>
            )}
            {currentColumns.isVisible('foreignTransactionId') && (
              <DataTable.HeadCell>{tr('FRONTEND.TRANSACTIONS.COLUMNS.FOREIGN_ID', 'Foreign ID')}</DataTable.HeadCell>
            )}
            {isSmall && (
              <DataTable.HeadCell className='no-border table-header-cell'>
                {tr('FRONTEND.TRANSACTIONS.COLUMNS.DATE', 'Date')}
              </DataTable.HeadCell>
            )}
            {currentColumns.isVisible('type') && (
              <DataTable.HeadCell className='no-border table-header-cell'>
                {tr('FRONTEND.TRANSACTIONS.COLUMNS.TYPE', 'Type')}
              </DataTable.HeadCell>
            )}
            {/* Recipient is missing , so I set the description column */}
            {isLarge && currentColumns.isVisible('description') && (
              <DataTable.HeadCell className='no-border table-header-cell'>
                {tr('FRONTEND.TRANSACTIONS.COLUMNS.DESCRIPTION', 'Description')}
              </DataTable.HeadCell>
            )}
            {currentColumns.isVisible('account') && (
              <DataTable.HeadCell className='no-border table-header-cell'>
                {tr('FRONTEND.TRANSACTIONS.COLUMNS.ACCOUNT', 'Account')}
              </DataTable.HeadCell>
            )}
            {currentColumns.isVisible('method') && (
              <DataTable.HeadCell className='no-border table-header-cell'>
                {tr('FRONTEND.TRANSACTIONS.COLUMNS.PAYMENT_METHOD', 'Payment Method')}
              </DataTable.HeadCell>
            )}
            {currentColumns.isVisible('reference') && (
              <DataTable.HeadCell className='no-border table-header-cell'>
                {tr('FRONTEND.TRANSACTIONS.COLUMNS.REFERENCE', 'Reference')}
              </DataTable.HeadCell>
            )}
            {currentColumns.isVisible('customerName') && (
              <DataTable.HeadCell>
                {tr('FRONTEND.TRANSACTIONS.COLUMNS.CUSTOMER_NAME', 'Customer name')}
              </DataTable.HeadCell>
            )}
            {currentColumns.isVisible('customer') && (
              <DataTable.HeadCell>
                {tr('FRONTEND.TRANSACTIONS.COLUMNS.CUSTOMER', 'Counterparty name')}
              </DataTable.HeadCell>
            )}
            {currentColumns.isVisible('failReason') && (
              <DataTable.HeadCell>
                {tr('FRONTEND.TRANSACTIONS.COLUMNS.STATUS_DESCRIPTION', 'Fail reason')}
              </DataTable.HeadCell>
            )}
            <DataTable.HeadCell
              style={{
                textAlign: 'right'
              }}
              className='no-border table-header-cell'>
              {tr('FRONTEND.TRANSACTIONS.COLUMNS.AMOUNT', 'Amount')}
            </DataTable.HeadCell>
            {currentColumns.isVisible('status') && (
              <DataTable.HeadCell className='thin no-border  table-header-cell'>
                {tr('FRONTEND.TRANSACTIONS.COLUMNS.STATUS', 'Status')}
              </DataTable.HeadCell>
            )}
            {/* <DataTable.HeadCell className='table-header-cell  buttons no-border' /> */}
          </DataTable.Row>
        </DataTable.Head>

        <DataTable.Body>
          {transactions.map((data: TransactionViewPortal, index: number) => {
            const {
              id,
              amount: transactionAmount,
              currency: transactionCurrency,
              status,
              reqTime,
              direction,
              reference,
              foreignTransactionId,
              paymentMethod,
              trnTypeLocalizationKey,
              transactionCategory,
              accountName,
              statusCodeLocalizationKey,
              statusCodeDefaultDescription,
              cardPaymentDetails
            } = data
            const { transactionAmount: cardPaymentAmount, transactionCurrency: cardPaymentCurrency } =
              cardPaymentDetails || {}

            const amount = cardPaymentAmount ? cardPaymentAmount : transactionAmount
            const currency = cardPaymentAmount ? cardPaymentCurrency : transactionCurrency

            return (
              <DataTable.Row
                key={`data-${index}`}
                data-test='table-row'
                onClick={() => {
                  handleDetailsClick(id)
                }}>
                {isMobile && currentColumns.isVisible('id') && (
                  <DataTable.Cell
                    className='no-border table-cell transaction-id'
                    caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.ID', 'ID')}
                    data-test='id'>
                    {id}
                  </DataTable.Cell>
                )}
                {currentColumns.isVisible('foreignTransactionId') && (
                  <DataTable.Cell
                    caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.FOREIGN_ID', 'Foreign ID')}
                    data-test='foreign-id'>
                    {foreignTransactionId}
                  </DataTable.Cell>
                )}
                {isSmall && (
                  <DataTable.Cell
                    caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.DATE', 'Date')}
                    className='no-wrap no-border table-cell'
                    data-test='date'>
                    <div className='transaction-date'>
                      <span>{moment(new Date(reqTime)).format('DD/MM/YYYY')}</span>
                      <span className='hours'>{moment(new Date(reqTime)).format('H:mm')}</span>
                    </div>
                  </DataTable.Cell>
                )}
                {currentColumns.isVisible('type') && (
                  <DataTable.Cell
                    className='no-border table-cell'
                    caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.TYPE', 'Type')}
                    data-test='type'>
                    {!isSmall ? (
                      <div>
                        <TransactionTypeWithIcon
                          transactionType={tr(trnTypeLocalizationKey, fetchLastChunk(trnTypeLocalizationKey))}
                          category={transactionCategory}
                          description={moment(new Date(reqTime)).format('DD/MM/YYYY H:mm')}
                        />
                      </div>
                    ) : (
                      <TransactionTypeWithIcon
                        transactionType={tr(trnTypeLocalizationKey, fetchLastChunk(trnTypeLocalizationKey))}
                        category={transactionCategory}
                      />
                    )}
                  </DataTable.Cell>
                )}
                {isLarge && currentColumns.isVisible('description') && (
                  <DataTable.Cell
                    className='no-border table-cell table-text-limits'
                    caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.DESCRIPTION', 'Description')}
                    data-test='description'>
                    {formatTransactionDescription(data)}
                  </DataTable.Cell>
                )}
                {isMobile && currentColumns.isVisible('account') && (
                  <DataTable.Cell
                    className='no-border table-cell'
                    caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.ACCOUNT', 'Account')}
                    data-test='account-name'>
                    {accountName}
                  </DataTable.Cell>
                )}
                {isMobile && currentColumns.isVisible('method') && (
                  <DataTable.Cell
                    className='no-border table-cell'
                    caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.PAYMENT_METHOD', 'Payment Method')}
                    data-test='payment-method'>
                    {tr(`METADATA.PAYMENT_OPTIONS.${paymentMethod}`, paymentMethod)}
                  </DataTable.Cell>
                )}
                {isMobile && currentColumns.isVisible('reference') && (
                  <DataTable.Cell
                    className='no-border table-cell table-text-limits'
                    caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.REFERENCE', 'Reference')}
                    data-test='reference'>
                    {reference ? (reference?.length > 50 ? cutString(reference, 50) + '...' : reference) : 'N/A'}
                  </DataTable.Cell>
                )}
                {currentColumns.isVisible('customerName') && (
                  <DataTable.Cell
                    caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.CUSTOMER_NAME', 'Customer name')}
                    data-test='customer-name'>
                    {extractTransactionCustomerName(data)}
                  </DataTable.Cell>
                )}
                {currentColumns.isVisible('customer') && (
                  <DataTable.Cell
                    caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.CUSTOMER', 'Counterparty')}
                    data-test='counterparty'>
                    {extractCounterpartyName(data)}
                  </DataTable.Cell>
                )}
                {currentColumns.isVisible('failReason') && (
                  <DataTable.Cell
                    caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.STATUS_DESCRIPTION', 'Fail reason')}
                    data-test='fail-reason'>
                    {isValidString(statusCodeLocalizationKey)
                      ? tr(statusCodeLocalizationKey, statusCodeDefaultDescription)
                      : statusCodeDefaultDescription}
                  </DataTable.Cell>
                )}
                <DataTable.Cell
                  className={`no-border table-cell`}
                  style={{
                    textAlign: 'right'
                  }}
                  caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.AMOUNT', 'Amount')}
                  data-test='amount'>
                  <div className='amount-container'>
                    <span>
                      {formatFloat(amount * (direction === 'WITHDRAW' ? -1 : 1), getCurrencyDecimals(currency))}{' '}
                      <span className='amount-container-currency'>{currency}</span>
                    </span>
                    {!isMobile && (
                      <CoreStatusView status={getTransactionStatusName(status) as TransactionStatusEnum} fade={true} />
                    )}
                  </div>
                </DataTable.Cell>
                {isMobile && currentColumns.isVisible('status') && (
                  <DataTable.Cell
                    caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.STATUS', 'Status')}
                    className='status-cell no-border table-cell'
                    data-test='status'>
                    <CoreStatusView status={getTransactionStatusName(status) as TransactionStatusEnum} />
                  </DataTable.Cell>
                )}

                {/* {isMobile && (
                  <DataTable.Cell
                    onClick={() => handleDetailsClick(id)}
                    className='no-border transaction-details-button table-cell'
                    table-cell>
                    <PreviewIcon />
                  </DataTable.Cell>
                )} */}
              </DataTable.Row>
            )
          })}
        </DataTable.Body>
      </DataTable.Table>
    </div>
  )
}

export default TransactionsTable
