import React from 'react'
import { useHistory } from 'react-router-dom'

import moment from 'moment'

import { breakpoints } from '@constants'
import { useMatchMedia } from '@hooks'
import { isOwnerOrAdministrator, paths, selectCurrentUserRole, useAppSelector } from 'mmfintech-backend-api'
import { formatDateToUserLocale, formatFloat, getCurrencyDecimals, isValidArray, tr } from 'mmfintech-commons'

import { Pagination } from '@components'
import { ReportStatusBadge, NoReportsComponent } from '@views/expenseManagement/components'

import { DataTable } from 'mmfintech-portal-commons'
import { ReportResponse, ReportStateEnum } from 'mmfintech-commons-types'

import RowIcon from '@images/icons/report-row-icon.svg?react'

import './reportList.scss'

interface ReportListProps {
  data: ReportResponse[]
  pagination: any
  isApprover?: boolean
}

export const ReportList: React.FC<ReportListProps> = ({ data, pagination, isApprover }) => {
  const history = useHistory()
  const customerRole = useAppSelector(selectCurrentUserRole)
  const isLarge = useMatchMedia({ breakpoint: breakpoints.lg, prefix: 'min' })
  const isXLarge = useMatchMedia({ breakpoint: breakpoints.xl, prefix: 'min' })

  const handleReportRowClick = (report: ReportResponse) => {
    if (
      // Check if the user is able to access payments page
      (report.state === ReportStateEnum.APPROVED ||
        report.state === ReportStateEnum.COMPLETELY_PAID ||
        report.state === ReportStateEnum.PARTIALLY_PAID ||
        report.state === ReportStateEnum.UNPAID) &&
      isOwnerOrAdministrator(customerRole)
    ) {
      history.push(paths.expenseManagement.payments(report?.id))
      return
    }

    history.push(paths.expenseManagement.report(report.id))
  }

  return (
    <>
      <div className='reports-table-wrapper table-container'>
        <DataTable.Table>
          {isValidArray(data) ? (
            <>
              {isLarge && (
                <DataTable.Head>
                  <DataTable.Row className='headRow'>
                    <DataTable.HeadCell colSpan={2}>{tr('FRONTEND.REPORTS.COLUMN_NAME', 'Name')}</DataTable.HeadCell>
                    {isApprover && isXLarge && (
                      <DataTable.HeadCell colSpan={2}>
                        {tr('FRONTEND.REPORTS.COLUMN_SUBMITTER', 'Submitter')}
                      </DataTable.HeadCell>
                    )}
                    {isXLarge && (
                      <DataTable.HeadCell>{tr('FRONTEND.REPORTS.COLUMN_PERIOD', 'Period')}</DataTable.HeadCell>
                    )}
                    <DataTable.HeadCell>{tr('FRONTEND.REPORTS.COLUMN_DESCRIPTION', 'Description')}</DataTable.HeadCell>
                    <DataTable.HeadCell>{tr('FRONTEND.REPORTS.COLUMN_EXPENSES', 'Expenses')}</DataTable.HeadCell>
                    <DataTable.HeadCell>{tr('FRONTEND.REPORTS.COLUMN_STATUS', 'Status')}</DataTable.HeadCell>
                    <DataTable.HeadCell className='text-right'>
                      {tr('FRONTEND.REPORTS.COLUMN_TOTAL', 'Total')}
                    </DataTable.HeadCell>
                  </DataTable.Row>
                </DataTable.Head>
              )}

              <DataTable.Body>
                {data.map((report: ReportResponse) => {
                  return (
                    <DataTable.Row
                      key={report.id}
                      data-test={`open-report-${report.id}`}
                      onClick={e => {
                        e.preventDefault()
                        e.stopPropagation()
                        handleReportRowClick(report)
                      }}>
                      <DataTable.Cell className='table-cell name' colSpan={2} data-test='name'>
                        <RowIcon style={{ width: '24px', height: '24px', display: 'block' }} />
                        <div>
                          {report.name}
                          {!isLarge &&
                            formatDateToUserLocale(report.fromDate) &&
                            formatDateToUserLocale(report.toDate) && (
                              <span className='period'>
                                {formatDateToUserLocale(report.fromDate)} - {formatDateToUserLocale(report.toDate)}
                              </span>
                            )}
                        </div>
                      </DataTable.Cell>
                      {isApprover && isXLarge && <DataTable.Cell colSpan={2}>{report.submitter}</DataTable.Cell>}
                      {isXLarge && (
                        <DataTable.Cell data-test={`period`}>
                          {`${moment(new Date(report.fromDate)).format('DD/MM/YYYY')} - ${moment(new Date(report.toDate)).format('DD/MM/YYYY')}`}
                        </DataTable.Cell>
                      )}
                      {isLarge && (
                        <>
                          <DataTable.Cell data-test={`description`}>{report.description}</DataTable.Cell>
                          <DataTable.Cell data-test={`expenses`}>{report.expenses.length}</DataTable.Cell>
                          <DataTable.Cell data-test={`status`}>
                            <ReportStatusBadge status={report.state} />
                          </DataTable.Cell>
                        </>
                      )}
                      <DataTable.Cell className='total text-right' data-test={`total`}>
                        <div className='amount'>
                          <span className='total-amount'>
                            {formatFloat(report.totalAmount, getCurrencyDecimals(report.currency))}
                            <span className='row-amount-currency'>{report.currency}</span>
                          </span>
                          {!isLarge && (
                            <span>
                              <ReportStatusBadge status={report.state} />
                            </span>
                          )}
                        </div>
                      </DataTable.Cell>
                    </DataTable.Row>
                  )
                })}
              </DataTable.Body>
            </>
          ) : (
            <NoReportsComponent title={'No Reports' /*todo: localize*/} />
          )}
        </DataTable.Table>
      </div>

      {isValidArray(data) && <Pagination {...pagination.register()} />}
    </>
  )
}
