import { breakpoints } from '@constants'
import { useMatchMedia } from '@hooks'
import { useMerchantAccounts, useMyPermissionsQry } from 'mmfintech-backend-api'
import { formatFloat, getCurrencyDecimals, isValidArray, tr } from 'mmfintech-commons'

import { DataTable, ErrorDisplay } from 'mmfintech-portal-commons'
import { CoreCurrencyIcon, CoreLoader } from '@components'

import './accountsOverview.scss'

type AccountOverviewRowProps = {
  id: number
  currencyCode: string
  balance: number
  name: string
  permission?: string
}

export const AccountsOverview = () => {
  const { permissions, permissionsFetching } = useMyPermissionsQry()
  const { activeAccounts, accountsFetching, accountsError } = useMerchantAccounts()
  const isLarge = useMatchMedia({ breakpoint: breakpoints['lg'], prefix: 'min' })

  const accounts = activeAccounts?.map((a: any) => {
    const permission = permissions?.find((perm: any) => a?.id === perm?.accountId)
    return { ...a, permission: permission?.accessLevel }
  })

  return (
    <div className='account-overview-list-wrapper'>
      {/* TODO: localization */}
      <h2 className='account-overview-list-title'>{tr('TODO', 'Accounts')}</h2>

      <ErrorDisplay error={accountsError} />

      {accountsFetching || permissionsFetching ? (
        <CoreLoader />
      ) : (
        <div className='table-container'>
          <DataTable.Table>
            <DataTable.Head>
              <DataTable.Row className='headRow'>
                <DataTable.HeadCell>
                  {tr('FRONTEND.USERS.EXPENSE_MANAGEMENT.DASHBOARD.ACCOUNT_OVERVIEW.CURRENCY', 'Currency')}
                </DataTable.HeadCell>
                <DataTable.HeadCell>
                  {tr('FRONTEND.USERS.EXPENSE_MANAGEMENT.DASHBOARD.ACCOUNT_OVERVIEW.ACCOUNT_NAME', 'Account Name')}
                </DataTable.HeadCell>
                <DataTable.HeadCell>
                  {tr('FRONTEND.USERS.EXPENSE_MANAGEMENT.DASHBOARD.ACCOUNT_OVERVIEW.PERMISSIONS', 'Permissions')}
                </DataTable.HeadCell>
                {isLarge && (
                  <DataTable.HeadCell>
                    {tr('FRONTEND.USERS.EXPENSE_MANAGEMENT.DASHBOARD.ACCOUNT_OVERVIEW.BALANCE', 'Balance')}
                  </DataTable.HeadCell>
                )}
              </DataTable.Row>
            </DataTable.Head>
            {isValidArray(accounts) && (
              <DataTable.Body>
                {accounts.map((account: AccountOverviewRowProps) => {
                  const { currencyCode, balance, name, permission, id } = account

                  return (
                    <DataTable.Row key={id}>
                      <DataTable.Cell className='accounts-cell'>
                        <div className='currency-box-container'>
                          <CoreCurrencyIcon currency={currencyCode} size='40px' circle />
                          <div className='currency-box'>
                            <span className='currency'>{currencyCode}</span>

                            {!isLarge && (
                              <span>{formatFloat(balance || 0, getCurrencyDecimals(currencyCode || ''))}</span>
                            )}
                          </div>
                        </div>
                      </DataTable.Cell>
                      <DataTable.Cell> {name}</DataTable.Cell>
                      <DataTable.Cell>
                        {permission === 'WRITER'
                          ? tr('FRONTEND.USERS.EXPENSE_MANAGEMENT.DASHBOARD.ACCOUNT_OVERVIEW.FULL.TITLE', 'Full')
                          : tr('FRONTEND.USERS.EXPENSE_MANAGEMENT.DASHBOARD.ACCOUNT_OVERVIEW.VIEW_ONLY', 'View Only')}
                      </DataTable.Cell>
                      {isLarge && (
                        <DataTable.Cell>
                          {formatFloat(balance || 0, getCurrencyDecimals(currencyCode || ''))} {currencyCode}
                        </DataTable.Cell>
                      )}
                    </DataTable.Row>
                  )
                })}
              </DataTable.Body>
            )}
          </DataTable.Table>
        </div>
      )}
    </div>
  )
}
