import { useState } from 'react'

import { tr } from 'mmfintech-commons'
import { selectCurrentUserId, useAppSelector } from 'mmfintech-backend-api'

import { CoreButton, CoreModalDialog } from '@components'
import { ReportDeleteModal, ReportEditModal } from '@views/expenseManagement/components'

import { ReportStateEnum } from 'mmfintech-commons-types'

import TrashIcon from '@images/icons/trash-icon.svg?react'
import EditReportNameIcon from '@images/expenses/edit-report-name.svg?react'

import './reportTitle.scss'

const actionStatuses = [ReportStateEnum.NEW, ReportStateEnum.EDIT]

export const ReportTitle = ({ report }) => {
  const [isReportEditModalVisible, setIsReportEditModalVisible] = useState<boolean>(false)
  const [isReportDeleteModalVisible, setIsReportDeleteModalVisible] = useState<boolean>(false)
  const userId = useAppSelector(selectCurrentUserId)

  const handleEditReportModalOpen = () => setIsReportEditModalVisible(true)
  const handleEditReportModalClose = () => setIsReportEditModalVisible(false)
  const handleDeleteReportModalOpen = () => setIsReportDeleteModalVisible(true)
  const handleDeleteReportModalClose = () => setIsReportDeleteModalVisible(false)

  return (
    <>
      <div className='report-title'>
        <span className='title'>{report?.name}</span>
        <div className='report-title-buttons'>
          {actionStatuses.includes(report?.state) && report.userId == userId && (
            <CoreButton
              onClick={handleEditReportModalOpen}
              CollapsedIcon={<EditReportNameIcon />}
              collapsed
              variation='elevated'
              size='normal'
            />
          )}
          {actionStatuses.includes(report?.state) && report.userId == userId && (
            <CoreButton
              onClick={handleDeleteReportModalOpen}
              CollapsedIcon={<TrashIcon />}
              collapsed
              variation='elevated'
              size='normal'
            />
          )}
        </div>
      </div>
      <CoreModalDialog
        header={tr('FRONTEND.REPORTS.CREATE.REPORT_DETAILS', 'Report Details')}
        options={{ closeOnEscape: false, closeOnClickOutside: false }}
        visible={isReportEditModalVisible}
        content={<ReportEditModal report={report} onClose={() => setIsReportEditModalVisible(false)} />}
        onClose={handleEditReportModalClose}
      />
      <CoreModalDialog
        header={tr('FRONTEND.EXPENSE_MANAGEMENT.DELETE_REPORT', 'Delete report')}
        options={{ closeOnEscape: false }}
        visible={isReportDeleteModalVisible}
        content={<ReportDeleteModal report={report} onClose={handleDeleteReportModalClose} />}
        onClose={handleDeleteReportModalClose}
      />
    </>
  )
}
