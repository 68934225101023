import { useState } from 'react'

import { checkFinalValue, isValidArray, tr, translateError } from 'mmfintech-commons'
import { useCurrencies } from 'mmfintech-backend-api'

import '@views/transactions/elements/styled/transactionFilter.scss'

import { CoreButton, CoreInput, CoreSelect } from '@components'

import ClearFiltersIcon from '@images/icons/transaction-refresh-filters-icon.svg?react'
import CalendarIcon from '@images/icons/transaction-search-calendar.svg?react'
import SearchIcon from '@images/icons/search-icon.svg?react'

export const ReportsSideFilter = ({ setApplyFilter, modalHide = null, filters, clearAll, countFilters }) => {
  const { currencies } = useCurrencies()
  const [errorFields, setErrorFields] = useState<string[]>([])

  const getCurrencyOptions = () => [
    { value: '', label: tr('FRONTEND.TRANSACTIONS.FILTER.ALL_CURRENCIES', 'All') },
    ...(isValidArray(currencies) ? currencies.map(c => ({ value: c.currencyCode, label: c.currencyCode })) : [])
  ]

  const validateInput = (inputName: string, options: any) => {
    const check = checkFinalValue(filters.get(inputName), options)
    if (check.valid) {
      if (errorFields.includes(inputName)) {
        setErrorFields(current => [...current].filter(val => val != inputName))
      }
      return ''
    }
    if (!errorFields.includes(inputName)) {
      setErrorFields(current => [...current, inputName])
    }
    return translateError(check)
  }

  return (
    <div className='transaction-filter-wrapper'>
      <div>
        <div className='transaction-filter-container'>
          <CoreInput
            className='transaction-filter-search'
            type='text'
            name='search'
            label={tr('FRONTEND.EXPENSES.FILTER.SEARCH', 'Search')}
            {...filters.registerInput('search')}
            placeholder={tr('FRONTEND.EXPENSES.FILTER.NAME_DESCRIPTION', 'Name, Description')}
            LeftIcon={<SearchIcon />}
            data-test='filter-search'
          />
          <div className='transaction-filter-multiple-inputs'>
            <CoreInput
              type='date'
              name='from'
              label={tr('FRONTEND.TRANSACTIONS.FILTER.LABEL.FROM', 'From')}
              LeftIcon={<CalendarIcon />}
              className='transaction-calendar-input'
              selectsStart
              rangeDates
              {...filters.registerInput('from')}
              startDate={filters.get('from')}
              endDate={filters.get('to')}
              data-test='filter-right-side-filter-from-date'
              clearable
              dateFormat='dd/MM/yyyy'
            />
            <CoreInput
              type='date'
              name='to'
              label={tr('FRONTEND.TRANSACTIONS.FILTER.LABEL.TO', 'To')}
              LeftIcon={<CalendarIcon />}
              className='transaction-calendar-input'
              selectsEnd
              rangeDates
              {...filters.registerInput('to')}
              startDate={filters.get('from')}
              endDate={filters.get('to')}
              data-test='filter-right-side-filter-to-date'
              clearable
              dateFormat='dd/MM/yyyy'
            />
          </div>

          <CoreSelect
            type='default'
            name='currency'
            label={tr('FRONTEND.TRANSACTIONS.FILTER.CURRENCY', 'Currency')}
            {...filters.registerInput('currency')}
            options={getCurrencyOptions()}
            value={filters.get('currency')}
            data-test='filter-currency'
          />

          <div className='transaction-filter-multiple-inputs'>
            <CoreInput
              type='text'
              name='fromAmount'
              label={tr('FRONTEND.TRANSACTIONS.FILTER.FROM_AMOUNT', 'From Amount')}
              {...filters.registerInput('fromAmount')}
              error={validateInput('fromAmount', { validation: 'numeric' })}
              data-test='filter-from-amount'
            />
            <CoreInput
              type='text'
              name='toAmount'
              label={tr('FRONTEND.TRANSACTIONS.FILTER.TO_AMOUNT', 'To Amount')}
              {...filters.registerInput('toAmount')}
              error={validateInput('toAmount', { validation: 'numeric' })}
              data-test='filter-to-amount'
            />
          </div>
        </div>
      </div>
      <div className='transaction-filter-footer-container'>
        <CoreButton
          fullWidth
          variation='primary'
          text={tr('FRONTEND.TRANSACTIONS.FILTER.BUTTON.APPLY', 'Apply')}
          onClick={() => {
            setApplyFilter(true)
            modalHide()
          }}
          data-test='button-apply'
          size='large'
          disabled={!!errorFields.length}
        />
        {countFilters > 0 && (
          <CoreButton
            variation='tertiary'
            onClick={() => {
              clearAll()
              setApplyFilter(true)
            }}
            LeftIcon={<ClearFiltersIcon />}
            size='large'
            text={tr('FRONTEND.TRANSACTIONS.FILTER.CLEAR_ALL_BUTTON', 'Clear All')}
            data-test='reset-filter-button'
          />
        )}
      </div>
    </div>
  )
}
