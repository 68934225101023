import { AppDispatch } from '../main'
import { useDispatch } from 'react-redux'

export * from './useCoreInfiniteScroll'
export * from './useDownloadFile'
export * from './useEditExternalBankAccount'
export * from './useInfiniteScroll'
export * from './useMatchMedia'
export * from './useOnboarding'
export * from './useOnboardingSteps'
export * from './useSearchParams'

export const useAppDispatch: () => AppDispatch = useDispatch
