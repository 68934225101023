import React from 'react'
import { useHistory } from 'react-router-dom'

import toast from 'react-hot-toast'

import { fixDateOnly, tr, useFormValues } from 'mmfintech-commons'
import { paths, useCreateReportMutation, useUpdateReportMutation } from 'mmfintech-backend-api'

import { ErrorDisplay } from 'mmfintech-portal-commons'
import { CoreButton, CoreInput, CoreModalDialogFooter } from '@components'

import { ReportResponse } from 'mmfintech-commons-types'

import CalendarIcon from '@images/icons/filter-calendar.svg?react'

import './reportEditModal.scss'

interface ReportEditProps {
  report?: ReportResponse
  onClose: () => void
  reload?: () => void
}

export const ReportEditModal: React.FC<ReportEditProps> = ({ report, onClose, reload }) => {
  const history = useHistory()

  const [createReportRequest, { isLoading: createIsLoading, error: createError }] = useCreateReportMutation({
    fixedCacheKey: 'refetch-reports'
  })
  const [editReportRequest, { isLoading: editIsLoading, error: editError }] = useUpdateReportMutation({
    fixedCacheKey: 'refetch-reports'
  })

  const formValues = useFormValues({
    name: { required: true, validation: 'safe-string', value: report?.name },
    description: { validation: 'safe-string', value: report?.description },
    fromDate: { required: true, value: report?.fromDate },
    toDate: { required: true, value: report?.toDate }
  })

  const createReport = async (e: React.BaseSyntheticEvent) => {
    e.preventDefault()
    if (formValues.areValid()) {
      try {
        const dateInfo = {
          fromDate: fixDateOnly(formValues.getValue('fromDate')),
          toDate: fixDateOnly(formValues.getValue('toDate'))
        }

        if (report?.id) {
          await editReportRequest({ ...formValues.prepare(), ...dateInfo, reportId: report?.id }).unwrap()
          toast.success(tr('FRONTEND.REPORTS.CREATE.EDITED_SUCCESSFULLY', 'Report edited successfully'))
          reload && reload()
          onClose()
        } else {
          const res: any = await createReportRequest({ ...formValues.prepare(), ...dateInfo }).unwrap()
          toast.success(tr('FRONTEND.REPORTS.CREATE.CREATED_SUCCESSFULLY', 'Report created successfully'))
          reload && reload()
          onClose()
          history.push(paths.expenseManagement.report(res?.data.id))
        }
      } catch (err) {
        formValues.handleErrors(err)
      }
    }
  }

  const buttonText = report?.id
    ? tr('FRONTEND.REPORTS.CREATE.SAVE', 'Save')
    : tr('FRONTEND.REPORTS.CREATE.CREATE_REPORT', 'Create Report')

  return (
    <div className='report-edit-modal-container'>
      <div className='form'>
        <CoreInput
          type='text'
          {...formValues.registerInput('name')}
          label={tr('FRONTEND.REPORTS.CREATE.REPORT_NAME', 'Report Name')}
        />
        <CoreInput
          type='text'
          {...formValues.registerInput('description')}
          label={tr('FRONTEND.REPORTS.CREATE.DESCRIPTION', 'Description')}
        />

        <div className='col'>
          <CoreInput
            type='date'
            {...formValues.registerInput('fromDate')}
            label={tr('FRONTEND.REPORTS.CREATE.FROM', 'From')}
            LeftIcon={<CalendarIcon />}
            maxDate={formValues.getValue('toDate')}
            parentClassName='calendar-input'
            dateFormat='dd/MM/yyyy'
            dataPickerForwardYears={5}
            rangeDates
            selectsStart
          />
          <CoreInput
            type='date'
            {...formValues.registerInput('toDate')}
            label={tr('FRONTEND.REPORTS.CREATE.TO', 'To')}
            LeftIcon={<CalendarIcon />}
            minDate={formValues.getValue('fromDate')}
            parentClassName='calendar-input'
            dateFormat='dd/MM/yyyy'
            dataPickerForwardYears={5}
            rangeDates
            selectsEnd
          />
        </div>
      </div>
      <ErrorDisplay error={createError || editError} />
      <CoreModalDialogFooter>
        <div className='buttons-container'>
          <CoreButton
            data-test='create-edit-report'
            isLoading={createIsLoading || editIsLoading}
            text={buttonText}
            onClick={createReport}
            size='large'
            fullWidth
          />
          <CoreButton
            variation='tertiary'
            data-test='close-edit-report'
            disabled={createIsLoading || editIsLoading}
            text={tr('FRONTEND.BUTTONS.CANCEL', 'Cancel')}
            onClick={onClose}
            size='large'
          />
        </div>
      </CoreModalDialogFooter>
    </div>
  )
}
