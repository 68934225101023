import { useContext, useEffect } from 'react'
import { useCookies } from 'react-cookie'

import {
  selectCurrentUserRole,
  selectInitialEmailVerification,
  useAppSelector,
  useAuth,
  useFetchNewsQuery,
  useGetMerchantQuery,
  useGetProfileQuery
} from 'mmfintech-backend-api'
import { CustomerRoleEnum, LoginStatusEnum, OnboardingStatusEnum, TwoFactorTypeEnum } from 'mmfintech-commons-types'
import { GlobalContext, isValidArray, isValidObject, tr } from 'mmfintech-commons'

import { CookieConsent, CoreModalDialog, FinallyRejectedModal, GoogleAuthenticatorInfoModal } from '@components'
import { AdminDashboard } from './AdminDashboard'
import { StaffDashboard } from './StaffDashboard'
import { WelcomeModal } from './WelcomeModal'
import { NewsModal } from './NewsModal'

export const Dashboard = () => {
  const initialEmailVerification = useAppSelector(selectInitialEmailVerification)
  const { modalShow } = useContext(GlobalContext)
  const [cookies] = useCookies(['authenticator'])
  const { data: profile } = useGetProfileQuery(null)
  const { twoFactor } = profile || {}

  const { data: merchant } = useGetMerchantQuery()
  const { onboardingStatus, merchantId } = merchant || {}
  const [modalCookies, setModalCookies] = useCookies(['cookie.modals'])
  const userStatus = useAuth()
  const customerRole = useAppSelector(selectCurrentUserRole)

  const { data: availableNews } = useFetchNewsQuery(undefined, { skip: userStatus !== LoginStatusEnum.LOGGED_IN })

  useEffect(() => {
    if (initialEmailVerification) {
      modalShow({
        header: tr('FRONTEND.WELCOME.MODAL.HEADING', 'Welcome to The Kingdom Bank!'),
        options: {
          closeOnClickOutside: false,
          closeOnEscape: false,
          hideCloseIcon: true,
          additionalActions: <div style={{ width: '64px' }} />
        },
        content: <WelcomeModal />
      })
    } else if (isValidArray(availableNews)) {
      modalShow({
        options: {
          size: 'centered',
          transparent: true,
          closeOnClickOutside: true,
          closeOnEscape: true,
          customSize: 48
        },
        content: <NewsModal news={availableNews} />
      })
    } else if (
      twoFactor &&
      merchantId &&
      !cookies?.authenticator?.includes(merchantId) &&
      twoFactor !== TwoFactorTypeEnum.TOTP
    ) {
      modalShow({
        options: {
          closeOnClickOutside: true,
          closeOnEscape: true,
          hideCloseIcon: true
        },
        content: <GoogleAuthenticatorInfoModal />
      })
    }
    // eslint-disable-next-line
  }, [initialEmailVerification, availableNews, merchantId, twoFactor])

  return (
    <>
      {onboardingStatus === OnboardingStatusEnum.REJECTED_FINAL && !isValidObject(modalCookies['cookie.modals']) && (
        <CoreModalDialog
          header={tr('FRONTEND.ONBOARDING.FINALLY_REJECTED_LABEL', 'Finally rejected')}
          visible={!isValidObject(modalCookies['cookie.modals'])}
          options={{
            hideCloseIcon: true
          }}
          content={<FinallyRejectedModal setCookie={setModalCookies} cookieConsent={CookieConsent} />}
        />
      )}
      {customerRole === CustomerRoleEnum.OWNER || customerRole === CustomerRoleEnum.ADMINISTRATOR ? (
        <AdminDashboard />
      ) : null}
      {customerRole === CustomerRoleEnum.STAFF && <StaffDashboard />}
    </>
  )
}
