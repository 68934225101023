import { useHistory } from 'react-router-dom'
import toast from 'react-hot-toast'

import { tr } from 'mmfintech-commons'
import { paths, useDeleteReportMutation } from 'mmfintech-backend-api'

import { ErrorDisplay } from 'mmfintech-portal-commons'
import { CoreButton, CoreModalDialogFooter } from '@components'

import { ReportResponse } from 'mmfintech-commons-types'

import TrashIcon from '@images/icons/trash-modal.svg?react'

import './reportDeleteModal.scss'
import React from 'react'

interface ReportDeleteProps {
  report: ReportResponse
  onClose: () => void
}

export const ReportDeleteModal = ({ report, onClose }: ReportDeleteProps) => {
  const history = useHistory()

  const [deleteReport, { error, isLoading }] = useDeleteReportMutation()

  const onDeleteReport = async (e: React.MouseEvent) => {
    e.stopPropagation()

    try {
      await deleteReport({ reportId: report.id }).unwrap()
      toast.success(tr('FRONTEND.REPORTS.ACTIONS.DELETE_REPORT_SUCCESS', 'Report deleted successfully'))
      history.push(paths.expenseManagement.expenseManagementDashboard())
      onClose()
    } catch {}
  }

  const onClickClose = (e: React.MouseEvent) => {
    e.stopPropagation()
    onClose()
  }

  return (
    <div className='delete-report-modal' onClick={e => e.stopPropagation()} onMouseDown={e => e.stopPropagation()}>
      <div className='icon'>
        <TrashIcon />
      </div>
      <div className='confirmation'>
        {tr('FRONTEND.REPORTS.ACTIONS.DELETE_REPORT_DESCRIPTION', 'Are you sure you want to delete report')}:{' '}
        <span>{report.name}</span>
      </div>
      <ErrorDisplay error={error} />

      <CoreModalDialogFooter>
        <div className='buttons-container'>
          <CoreButton
            text={tr('FRONTEND.BUTTONS.DELETE', 'Delete')}
            onClick={onDeleteReport}
            data-test='delete-report-button'
            isLoading={isLoading}
            fullWidth
            size='large'
          />
          <CoreButton
            variation='tertiary'
            text={tr('FRONTEND.BUTTONS.CANCEL', 'Cancel')}
            onClick={onClickClose}
            data-test='close-delete-report-button'
            isLoading={isLoading}
            size='large'
          />
        </div>
      </CoreModalDialogFooter>
    </div>
  )
}
