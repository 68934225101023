import { formatDate, tr } from 'mmfintech-commons'
import { useGetReportActivitiesQuery } from 'mmfintech-backend-api'

import { ReportAction, ReportActionEnum, ReportActivityResponse } from 'mmfintech-commons-types'

import CalendarIcon from '@images/icons/calendar-icon.svg?react'

import './activitiesSidebar.scss'

const actionMap = (action: ReportAction) => {
  switch (action) {
    case ReportActionEnum.APPROVE:
      return tr('FRONTEND.EXPENSE_MANAGEMENT.ACTIVITY_STATUS.APPROVE', 'Approve')
    case ReportActionEnum.REJECT:
      return tr('FRONTEND.EXPENSE_MANAGEMENT.ACTIVITY_STATUS.REJECT', 'Reject')
    case ReportActionEnum.REQUEST_EDIT:
      return tr('FRONTEND.EXPENSE_MANAGEMENT.ACTIVITY_STATUS.REQUEST_EDIT', 'Request Edit')

    default:
      return 'Invalid Action'
  }
}

export const ActivitiesSidebar = ({ reportId }) => {
  const { data: activities } = useGetReportActivitiesQuery({ reportId }, { skip: !reportId })

  return (
    <div className='activities-wrapper'>
      <div className='activities-body'>
        {Array.isArray(activities) &&
          activities.map(({ action, comment, id, updateTime, approver }: ReportActivityResponse) => (
            <div className='activity' key={id}>
              <div className='upper'>
                <CalendarIcon />
                <span>{formatDate(updateTime)}</span>
              </div>
              <div className='activity'>
                <p className='username'>{approver}</p>
                {action && (
                  <div className='details'>
                    <span className='detail-label'>{tr('FRONTEND.REPORTS.HISTORY_LABEL.ACTION', 'Action')}</span>
                    <span className='comment'>{actionMap(action)}</span>
                  </div>
                )}
                {comment && (
                  <div className='details'>
                    <span className='detail-label'>{tr('FRONTEND.REPORTS.HISTORY_LABEL.COMMENT', 'Comment')}</span>
                    <span className='comment'>{comment}</span>
                  </div>
                )}
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}
