import React from 'react'

import cn from 'classnames'
import { tr } from 'mmfintech-commons'

import { CounterpartyStatusEnum, ReportStateEnum } from 'mmfintech-commons-types'

import './reportStatusBadge.scss'

// Function to determine color based on status
const getStatusLabel = (status: string) => {
  switch (status) {
    // Counterparties status labels
    case CounterpartyStatusEnum.ACTIVE:
      return tr('FRONTEND.REPORTS.STATUSES.ACTIVE', 'Active')
    case CounterpartyStatusEnum.ARCHIVED:
      return tr('FRONTEND.REPORTS.STATUSES.ARCHIVED', 'Archived')
    //End Counterparties status labels

    case ReportStateEnum.NEW:
      return tr('FRONTEND.REPORTS.STATUSES.DRAFT', 'Draft')
    case ReportStateEnum.EDIT:
      return tr('FRONTEND.REPORTS.STATUSES.RETURNED', 'Returned')
    case ReportStateEnum.APPROVED:
      return tr('FRONTEND.REPORTS.STATUSES.AWAITING_PAYMENT', 'Awaiting payment')
    case ReportStateEnum.WAITING_APPROVAL:
      return tr('FRONTEND.REPORTS.STATUSES.AWAITING_APPROVAL', 'Awaiting approval')
    case ReportStateEnum.WAITING_FINANCE_APPROVAL:
      return tr('FRONTEND.REPORTS.STATUSES.AWAITING_FINANCE_APPROVAL', 'Awaiting finance approval')
    case ReportStateEnum.REJECTED:
      return tr('FRONTEND.REPORTS.STATUSES.REJECTED', 'Rejected')
    case ReportStateEnum.COMPLETELY_PAID:
      return tr('FRONTEND.REPORTS.STATUSES.PAID', 'Paid')
    case ReportStateEnum.PARTIALLY_PAID:
      return tr('FRONTEND.REPORTS.STATUSES.PARTIALLY_PAID', 'Partially paid')
    case ReportStateEnum.UNPAID:
      return tr('FRONTEND.REPORTS.STATUSES.UNPAID', 'Unpaid')
    default:
      return status
  }
}

export const ReportStatusBadge: React.FC<{ status: string }> = ({ status }) => (
  <div className={cn('report-status-badge-container', status)}>
    <span className='label'>{getStatusLabel(status)}</span>
  </div>
)
