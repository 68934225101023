import toast from 'react-hot-toast'
import { useContext } from 'react'

import { paths, useSearchExternalInstrumentsQuery } from 'mmfintech-backend-api'
import { GlobalContext, isValidArray, tr } from 'mmfintech-commons'
import { ErrorDisplay } from 'mmfintech-portal-commons'

import { EditRecipientModal } from '@views/recipients/elements/EditRecipientModal'
import { Breadcrumb, CoreButton } from '@components'

import EditReportNameIcon from '@images/expenses/edit-report-name.svg?react'
import LeftArrowIcon from '@images/icons/left-arrow-icon.svg?react'

import './recipientDetailsHeader.scss'

const BREADCRUMB_ITEMS: { label: string; path: (id?: string) => string }[] = [
  {
    label: 'Reports',
    path: () => paths.expenseManagement.expenseManagementDashboard()
  },
  {
    label: 'Counterparties',
    path: () => paths.expenseManagement.counterParty()
  },
  {
    label: 'Counterparties Detail',
    path: () => 'back'
  },
  {
    label: 'Recipients Detail',
    path: (id: string) => paths.recipients.details(id)
  }
]

export const RecipientDetailsHeader = ({ param }) => {
  const { modalHide, modalShow } = useContext(GlobalContext)
  const { recipient, recipientError, recipientFetching } = useSearchExternalInstrumentsQuery(
    { paymentInstrumentId: param },
    {
      selectFromResult: ({ data, isFetching, error }) => ({
        recipient: isValidArray(data?.content) ? data.content[0] : {},
        recipientFetching: isFetching,
        recipientError: error
      })
    }
  )

  const handleEditRecipient = () => {
    modalShow({
      header: tr('FRONTEND.RECIPIENTS.LIST.EDIT.TITLE', 'Edit recipient'),
      content: (
        <EditRecipientModal
          onClose={() => {
            modalHide()
          }}
          onSuccess={() => {
            toast.remove()
            toast.success(tr('FRONTEND.RECIPIENTS.EDIT.SUCCESS', 'Recipient edited!'))
            modalHide()
          }}
          recipient={recipient}
        />
      )
    })
  }

  return (
    <div className='recipient-details-page-header'>
      <ErrorDisplay error={recipientError} />
      {!recipientFetching && (
        <>
          <div className='recipient-details-header-title'>
            <div
              onClick={e => {
                e.preventDefault()
                window.history.back()
              }}
              className='back-arrow-icon'>
              <LeftArrowIcon />
            </div>

            <span className='title'>{recipient?.name}</span>
            <CoreButton
              data-test='open-edit-counterparty-modal'
              onClick={handleEditRecipient}
              CollapsedIcon={<EditReportNameIcon />}
              collapsed
              variation='elevated'
              size='large'
            />
          </div>
          <Breadcrumb breadcrumbItems={BREADCRUMB_ITEMS} />
        </>
      )}
    </div>
  )
}
