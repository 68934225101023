import { useEffect, useState } from 'react'

import { useLazyGetCounterpartiesQuery } from 'mmfintech-backend-api'
import { tr, useDelayedExecute, useFilter, usePaginationQuery } from 'mmfintech-commons'

import CounterpartyTable from './elements/CounterpartyTable/CounterpartyTable'
import { CoreLoader, ExpenseTabs, Pagination } from '@components'

import { CounterpartyStatusEnum } from 'mmfintech-commons-types'

import './counterpartyDashboard.scss'

const CounterpartyDashboard = () => {
  const tabConfig = [
    {
      title: tr('FRONTEND.COUNTERPARTY.TABS.ACTIVE', 'Active'),
      name: 'active'
    },
    {
      title: tr('FRONTEND.COUNTERPARTY.TABS.ARCHIVED', 'Archived'),
      name: 'archived'
    },
    {
      title: tr('FRONTEND.COUNTERPARTY.TABS.ALL', 'All'),
      name: 'all'
    }
  ]

  const [activeTab, setActiveTab] = useState(tabConfig[0].name)
  const [getCounterparties, { data, isLoading, isFetching }] = useLazyGetCounterpartiesQuery()

  const filter = useFilter(
    {
      state: CounterpartyStatusEnum.ACTIVE
    },
    [],
    [],
    () => {
      delayedReload.execute()
    },
    true
  )

  const delayedReload = useDelayedExecute({
    name: 'delayedFiltersReload',
    onExecute: () => pagination.reload({ page: 0 })
  })

  const pagination = usePaginationQuery({
    rowsPerPage: 7,
    reload: async (params: any, onSuccess: (response: any) => void) => {
      try {
        const response = await getCounterparties({
          ...params,
          ...filter.prepare(),
          includeExpenseMetrics: false,
          owner: false
        }).unwrap()

        onSuccess(response)
      } catch {}
    }
  })

  const handleTabChange = (tab: string) => {
    if (tab !== activeTab) {
      setActiveTab(tab)
      const state =
        tab === 'active' ? CounterpartyStatusEnum.ACTIVE : tab === 'archived' ? CounterpartyStatusEnum.ARCHIVED : null
      filter.set('state', state)
    }
  }

  useEffect(() => {
    pagination.reload()
  }, [activeTab])

  return (
    <div className='counterparty-wrapper'>
      <div className='table-wrapper'>
        <div className='counterparty-header'>
          <ExpenseTabs config={tabConfig} currentTab={activeTab} setTab={handleTabChange} />
        </div>

        {isLoading || isFetching ? (
          <CoreLoader />
        ) : (
          <>
            <CounterpartyTable data={data?.content || []} />
            <Pagination {...pagination.register()} />
          </>
        )}
      </div>
    </div>
  )
}

export default CounterpartyDashboard
