import { ErrorDisplay } from 'mmfintech-portal-commons'

import { useIntraAccountTransferInitiateMutation, useIntraAccountTransferPreviewMutation } from 'mmfintech-backend-api'
import { AccountBalanceOut } from 'mmfintech-commons-types'
import { tr, formatFloat, getCurrencyDecimals } from 'mmfintech-commons'

import { BetweenAccountsSendMoneyValueLabel, CoreButton } from '@components'
import { SupportBlock } from '@views/banking/Deposit/elements/SupportBlock'

interface IntraAccountTransferPreviewProps {
  onContinue: () => void
  onCancel: () => void
  fromAccount: AccountBalanceOut
  toAccount: AccountBalanceOut
}

export const IntraAccountTransferPreview = ({
  onContinue,
  onCancel,
  fromAccount,
  toAccount
}: IntraAccountTransferPreviewProps) => {
  const [_, { data: intraAccountTransferPreview }] = useIntraAccountTransferPreviewMutation({
    fixedCacheKey: 'intra-account-transfer-preview'
  })
  const [__, { error: initiateError, isLoading: initiateLoading }] = useIntraAccountTransferInitiateMutation({
    fixedCacheKey: 'intra-account-transfer-initiate'
  })

  const {
    fee,
    feeCurrency,
    fxRate,
    processingAmount,
    toCurrency,
    processingCurrency,
    walletAmount,
    walletCurrency,
    toAmount
  } = intraAccountTransferPreview || {}

  return (
    <>
      <div className='send-money-container'>
        <div className='send-money-between-accounts-title'>{tr('FRONTEND.SEND_OWN_ACCOUNTS.PREVIEW.FROM', 'From')}</div>
        <div className='send-money-inside-container send-money-details'>
          <BetweenAccountsSendMoneyValueLabel
            account={fromAccount}
            walletAmount={walletAmount}
            walletCurrency={walletCurrency}
            dataTest='account-name'
          />
        </div>
        <div className='send-money-between-accounts-title'>{tr('FRONTEND.SEND_OWN_ACCOUNTS.PREVIEW.TO', 'To')}</div>
        <div className='send-money-inside-container send-money-details'>
          <BetweenAccountsSendMoneyValueLabel
            account={toAccount}
            toAmount={toAmount || processingAmount}
            processingCurrency={processingCurrency || toCurrency}
            dataTest='recipient-name'
          />
        </div>

        {fxRate !== 1 && (
          <div className='send-money-between-account-preview-rate'>
            <div className='send-money-between-accounts-dot'></div>
            <span className='send-money-between-account-preview-rate-label'>{`${tr(
              'FRONTEND.EXCHANGE.RATE',
              'Exchange rate'
            )}: `}</span>
            <span className='send-money-between-account-preview-rate-content'>
              {'1 '}
              <span className='send-money-between-account-preview-currency'>{walletCurrency}</span>
              {' = '}
              {fxRate} <span className='send-money-between-account-preview-currency'>{toCurrency}</span>
            </span>
          </div>
        )}
        {fee > 0 && (
          <div className='send-money-between-account-preview-fee'>
            <div className='send-money-between-accounts-dot'></div>
            <span className='send-money-between-account-preview-fee-label'>{`${tr(
              'FRONTEND.EXCHANGE.FEE',
              'Fee'
            )}: `}</span>
            <span className='send-money-between-account-preview-fee-content'>
              {formatFloat(fee, getCurrencyDecimals(toCurrency))}{' '}
              <span className='send-money-between-account-preview-currency'>{feeCurrency}</span>
            </span>
          </div>
        )}

        <ErrorDisplay error={initiateError} />

        <div className='preview-actions'>
          <CoreButton
            text={tr('FRONTEND.BUTTONS.CONTINUE', 'Continue')}
            onClick={onContinue}
            isLoading={initiateLoading}
            disabled={initiateLoading}
            className='mb-1'
            data-test='continue-button'
            size='large'
            fullWidth
          />
          <CoreButton
            text={tr('FRONTEND.BUTTONS.CANCEL', 'Cancel')}
            variation='tertiary'
            onClick={onCancel}
            disabled={initiateLoading}
            data-test='button-cancel'
            size='large'
          />
        </div>
      </div>
      <SupportBlock />
    </>
  )
}
