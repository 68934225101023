import { useState, useEffect } from 'react'
import toast from 'react-hot-toast'

import {
  useCreateCounterpartyMutation,
  useGetMerchantAttributesQuery,
  useGetMerchantQuery,
  useLazySearchExternalInstrumentsQuery,
  useSetMerchantAttributesMutation
} from 'mmfintech-backend-api'
import { ErrorDisplay } from 'mmfintech-portal-commons'
import { isValidArray, tr } from 'mmfintech-commons'

import { BankAccountRecipientEdit } from '@views/recipients/elements/BankAccountRecipientEdit'
import { CoreButton, CoreLoader, CoreModalDialog } from '@components'
import { NoPaymentDetails } from './NoPaymentDetails'
import { PaymentPreview } from './PaymentPreview'

import EditReportNameIcon from '@images/expenses/edit-report-name.svg?react'
import AddIcon from '@images/icons/cross-icon.svg?react'

import './paymentInfo.scss'

export const Payments = () => {
  const [open, setOpen] = useState(false)
  const [fetchRecipients, { recipients, recipientsError, recipientsFetching, recipientsLoading }] =
    useLazySearchExternalInstrumentsQuery({
      selectFromResult: ({ data, error, isFetching, isLoading }) => ({
        recipients: isValidArray(data?.content) ? data.content : [],
        recipientsError: error,
        recipientsLoading: isLoading,
        recipientsFetching: isFetching
      })
    })
  const { data: merchant, isLoading: isLoadingMerchant } = useGetMerchantQuery(null)
  const { data: userAttributes, isSuccess } = useGetMerchantAttributesQuery()
  const [createCounterparty, { data: counterparty, isLoading }] = useCreateCounterpartyMutation()
  const [updateMyAttributes, { isLoading: isAttLoading }] = useSetMerchantAttributesMutation()

  useEffect(() => {
    if (merchant?.name) {
      ;(async () => {
        try {
          await createCounterparty({ name: merchant?.name, type: 'EMPLOYEE' }).unwrap()
        } catch (error) {
          toast.error(error.error)
        }
      })()
    }
  }, [merchant])

  useEffect(() => {
    if (counterparty) {
      fetchRecipients({ foreignId: String(counterparty?.id || '') })
    }
  }, [counterparty, merchant])

  const { preferredCurrency } = isSuccess && userAttributes

  const openModal = () => setOpen(true)
  const closeModal = () => setOpen(false)

  const handleSuccess = async (response: any) => {
    await fetchRecipients({ foreignId: String(counterparty?.id || '') })
    closeModal()

    if (response?.currency !== preferredCurrency) {
      try {
        await updateMyAttributes({ preferredCurrency: response?.currency }).unwrap()
      } catch (error) {
        toast.error(error.error)
      }
    }
  }

  return (
    <div className='payments-wrapper'>
      <div className='payments-container'>
        {isLoading || recipientsFetching || recipientsLoading || isAttLoading || isLoadingMerchant ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CoreLoader />
          </div>
        ) : (
          <>
            <div className='payments-header'>
              <span className='security-title' data-test='security-title'>
                {tr('FRONTEND.PAYMENT.TITLE', 'Payment Details')}
              </span>
              {recipients?.[0]?.paymentInstrumentId ? (
                <CoreButton
                  LeftIcon={<EditReportNameIcon />}
                  size='normal'
                  variation='elevated'
                  text={tr('FRONTEND.BUTTONS.EDIT', 'Edit')}
                  onClick={openModal}
                />
              ) : (
                <CoreButton
                  LeftIcon={<AddIcon />}
                  text={tr('FRONTEND.SETTINGS_PAYMENT.LABELS.ADD_DETAILS', 'Add details')}
                  onClick={openModal}
                  size='normal'
                />
              )}
            </div>

            <div className='payment-content'>
              {recipients?.[0]?.paymentInstrumentId ? (
                <PaymentPreview recipient={recipients[0]} />
              ) : (
                <NoPaymentDetails />
              )}
            </div>

            <ErrorDisplay error={recipientsError} />
            <CoreModalDialog
              header={
                recipients?.[0]?.paymentInstrumentId > 0 ? (
                  <div className='recipient-edit-header'>
                    {tr('FRONTEND.PAYMENT.DETAILS.EDIT', 'Edit payment details')}
                  </div>
                ) : (
                  <div className='title single'>{tr('FRONTEND.PAYMENT.DETAILS.ADD', 'Payment details')}</div>
                )
              }
              visible={open}
              onClose={closeModal}
              content={
                recipients?.[0]?.paymentInstrumentId ? (
                  // Edit
                  <BankAccountRecipientEdit
                    recipientId={recipients?.[0]?.paymentInstrumentId}
                    onSuccess={handleSuccess}
                    currencyCode={preferredCurrency}
                    isEditableCurrency={true}
                  />
                ) : (
                  // Create
                  <BankAccountRecipientEdit
                    recipientId={null}
                    counterpartyId={counterparty?.id}
                    onSuccess={handleSuccess}
                    currencyCode={preferredCurrency}
                    isEditableCurrency={true}
                  />
                )
              }
            />
          </>
        )}
      </div>
    </div>
  )
}
