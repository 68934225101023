import { tr } from 'mmfintech-commons'
import { selectCurrentUserRole, useAppSelector, useGetReportQuery } from 'mmfintech-backend-api'

import { CustomerRoleEnum, ReportStateEnum } from 'mmfintech-commons-types'

import ApprovedIcon from '@images/icons/success-toaster-icon.svg?react'
import ReturnedIcon from '@images/expenses/returned-report.svg?react'
import RejectedIcon from '@images/icons/error-toaster-icon.svg?react'

import './reportActionBadge.scss'

export const ReportActionBadge = ({ reportId }) => {
  const customerRole = useAppSelector(selectCurrentUserRole)

  const { data } = useGetReportQuery({ reportId }, { skip: !reportId })
  const { state } = data || {}

  const BadgeStyled = ({ message, icon: Icon = null }) => (
    <div className='report-action-badge-container'>
      {Icon && <Icon />}
      <div>{message}</div>
    </div>
  )

  switch (state) {
    case ReportStateEnum.WAITING_FINANCE_APPROVAL:
    case ReportStateEnum.APPROVED:
      return (
        <BadgeStyled
          icon={ApprovedIcon}
          message={tr('FRONTEND.REPORTS.STATE.APPROVED', 'Report was approved successfully')}
        />
      )

    case ReportStateEnum.REJECTED:
      return <BadgeStyled icon={RejectedIcon} message={tr('FRONTEND.REPORTS.STATE.REJECTED', 'Report rejected')} />

    case ReportStateEnum.EDIT:
      return <BadgeStyled icon={ReturnedIcon} message={tr('FRONTEND.REPORTS.STATE.RETURNED', 'Report returned')} />

    case ReportStateEnum.WAITING_APPROVAL:
      if (customerRole === CustomerRoleEnum.SUBMITTER) {
        return (
          <BadgeStyled
            icon={ApprovedIcon}
            message={tr('FRONTEND.REPORTS.STATE.SUBMITTED', 'Report was submitted successfully')}
          />
        )
      }
      break

    default:
    // nothing
  }

  return <BadgeStyled message={formatSnakeCaseToTitleCase(state || '')} />
}

const formatSnakeCaseToTitleCase = (snakeCaseString: string): string =>
  snakeCaseString
    .toLowerCase() // Convert the entire string to lowercase
    .replace(/_/g, ' ') // Replace underscores with spaces
    .replace(/\b\w/g, char => char.toUpperCase()) // Capitalize the first letter of each word
