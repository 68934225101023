import React, { useState } from 'react'

import { tr } from 'mmfintech-commons'

import { ReportSubmitModal } from './ReportSubmitModal'
import { CoreButton, CoreModalDialog } from '@components'

import { ReportResponse } from 'mmfintech-commons-types'

import SubmitReportIcon from '@images/expenses/submit-report.svg?react'

interface SubmitButtonProps {
  report: ReportResponse
}

export const ReportSubmitButton: React.FC<SubmitButtonProps> = ({ report }) => {
  const [isSubmitModalVisible, setIsSubmitModalModalVisible] = useState<boolean>(false)

  const handleSubmitReportModalClose = () => setIsSubmitModalModalVisible(false)
  const handleSubmitReportModalOpen = () => setIsSubmitModalModalVisible(true)

  return (
    <>
      <CoreButton
        text={tr('FRONTEND.EXPENSE_MANAGEMENT.SUBMIT_REPORT', 'Submit Report')}
        LeftIcon={<SubmitReportIcon />}
        data-test='button-submit-report-modal'
        onClick={handleSubmitReportModalOpen}
        size='large'
      />
      <CoreModalDialog
        header={tr('FRONTEND.EXPENSE_MANAGEMENT.SUBMIT_REPORT', 'Submit Report')}
        options={{ closeOnEscape: false, closeOnClickOutside: false }}
        visible={isSubmitModalVisible}
        content={<ReportSubmitModal report={report} onClose={handleSubmitReportModalClose} />}
        onClose={handleSubmitReportModalClose}
      />
    </>
  )
}
