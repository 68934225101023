import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import cn from 'classnames'

import { PaymentMethod, WithdrawalSubFlow, WithdrawalSubFlowEnum } from 'mmfintech-commons-types'
import { paths, useLazyWithdrawAccountsQuery, useMyPermissionsQry, useWithdrawSubFlows } from 'mmfintech-backend-api'
import { GlobalContext, tr, isValidArray } from 'mmfintech-commons'
import { ErrorDisplay } from 'mmfintech-portal-commons'

import { AccessDenied, CoreLoader, PaymentMethods } from '@components'

import { useMatchMedia } from '@hooks'

// VIEWS
import DigitalWalletTransfer from '../Withdraw/DigitalWallet'
import PerfectMoneyTransfer from '../Withdraw/PerfectMoney'
import IntraAccountTransfer from '../IntraAccountTransfer'
import InteractTransfer from '../Withdraw/Interac'
import InternalTransfer from '../InternalTransfer'
import CryptoTransfer from '../CryptoTransfer'
import BankTransfer from '../BankTransfer'
import PixTransfer from '../Withdraw/Pix'
// VIEWS

import { NoSuitableAccountModal } from './NoSuitableAccountModal'

import { HeaderContext } from '../../../context/HeaderContext'

import ArrowIcon from '@images/icons/arrow-down.svg?react'

import './sendMoney.scss'

const sendMoneySteps = [
  {
    step: 1,
    context: tr('FRONTEND.BANKING.SEND_MONEY.METHOD_ACCOUNT_LABEL', 'Select Method & Account'),
    subtitle: ''
  },
  {
    step: 2,
    context: tr('FRONTEND.BANKING.SEND_MONEY.REVIEW_CONFIRM_LABEL', 'Review & Confirm'),
    subtitle: ''
  }
]

const priorityMethods = ['BANKWIRE', 'INTRA_ACCOUNT_TRANSFER', 'INTERNAL_TRANSFER', 'CRYPTO']

export const SendMoney = () => {
  const [fetchAccounts, { isLoading: accountsLoading, isFetching: accountsFetching, error: accountsError }] =
    useLazyWithdrawAccountsQuery()
  const { withdrawSubFlows, withdrawSubFlowsError, withdrawSubFlowsFetching } = useWithdrawSubFlows()
  const { hasAnyWritePermission, permissionsFetching } = useMyPermissionsQry()

  const { setCurrentStep, currentStep, setTotalSteps } = useContext(HeaderContext)
  const { modalHide, modalShow } = useContext(GlobalContext)

  const history = useHistory()
  const location = useLocation()

  const [selectedPaymentOption, setSelectedPaymentOption] = useState<any>({
    paymentMethod: location?.pathname ? activePathMap[location.pathname] : null
  })
  const [action, setAction] = useState<string>(location?.pathname ? activePathMap[location.pathname] : '')
  const [collapsePaymentOption, setCollapsePaymentOption] = useState<boolean>(false)
  const [convertedPaymentOptions, setConvertedPaymentOptions] = useState([])

  const paymentOptionsCollapseEnable = useMatchMedia({ breakpoint: 1280, prefix: 'max' })

  useEffect(() => {
    setTotalSteps(sendMoneySteps)

    return () => {
      setCurrentStep(1)
    }
  }, [])

  useEffect(() => {
    if (selectedPaymentOption && paymentOptionsCollapseEnable) {
      return setCollapsePaymentOption(false)
    }
    if (!selectedPaymentOption && paymentOptionsCollapseEnable) {
      setCollapsePaymentOption(true)
    }
  }, [selectedPaymentOption, paymentOptionsCollapseEnable])

  const showNoSuitableAccountModal = flowType => {
    modalShow({
      options: { size: 'medium', closeOnClickOutside: false },
      header: subFlows(flowType)?.title,
      content: <NoSuitableAccountModal onClose={modalHide} />
    })
  }

  const handlePaymentOptionChanged = async (flowType: WithdrawalSubFlow, redirectUrl: string) => {
    setSelectedPaymentOption({ paymentMethod: flowType })

    if (flowType === WithdrawalSubFlowEnum.BATCH) {
      history.push(redirectUrl)
      return
    }

    try {
      const accounts = await fetchAccounts(flowType).unwrap()
      if (isValidArray(accounts)) {
        setAction(flowType)
        history.push(redirectUrl)
      } else {
        setSelectedPaymentOption({ paymentMethod: action })
        showNoSuitableAccountModal(flowType)
      }
    } catch {}
  }

  useEffect(() => {
    if (withdrawSubFlows && isValidArray(withdrawSubFlows)) {
      const methods = withdrawSubFlows.map(flowType => {
        const method = subFlows(flowType)
        if (method) {
          return method
        }

        return null
      })

      const sortedArray = methods.filter(Boolean).sort((a, b) => {
        const aPriority =
          priorityMethods.indexOf(a.paymentMethod) !== -1 ? priorityMethods.indexOf(a.paymentMethod) : Infinity
        const bPriority =
          priorityMethods.indexOf(b.paymentMethod) !== -1 ? priorityMethods.indexOf(b.paymentMethod) : Infinity
        return aPriority - bPriority
      })

      setConvertedPaymentOptions(sortedArray?.filter(n => n))
    }
  }, [withdrawSubFlows])

  return (
    <div className='send-money-wrapper'>
      <ErrorDisplay error={[accountsError, withdrawSubFlowsError]} />

      {withdrawSubFlowsFetching || permissionsFetching ? (
        <CoreLoader />
      ) : !hasAnyWritePermission ? (
        <AccessDenied />
      ) : (
        <div className='send-wrapper'>
          {currentStep === 1 && (
            <div className='send-navigation-wrapper'>
              <div className={cn('methods-container', { collapsed: collapsePaymentOption })}>
                <span className='navigation-title'>
                  {withdrawSubFlows?.length == 1
                    ? tr('FRONTEND.BANKING.SEND_MONEY.PAYMENT_METHODS_LABEL', 'Payment method')
                    : tr('FRONTEND.BANKING.SEND_MONEY.PAYMENT_METHODS_LABEL_SELECT', 'Select payment method')}
                </span>
                {paymentOptionsCollapseEnable && (
                  <span className='select-method-arrow' onClick={() => setCollapsePaymentOption(val => !val)}>
                    <ArrowIcon />
                  </span>
                )}
              </div>
              <div className='send-navigation-container'>
                {convertedPaymentOptions && convertedPaymentOptions?.length > 0 && (
                  <PaymentMethods
                    paymentOptions={convertedPaymentOptions}
                    setSelectedPaymentOption={handlePaymentOptionChanged}
                    selectedPaymentOption={selectedPaymentOption}
                    loading={accountsFetching || accountsLoading}
                    disabled={accountsFetching || accountsLoading}
                    collapse={collapsePaymentOption}
                    setCollapse={setCollapsePaymentOption}
                    ignoreStatus={true}
                  />
                )}
              </div>
            </div>
          )}

          <div className='send-content-wrapper'>
            {(accountsLoading || accountsFetching) && (
              <div className='accounts-loader'>
                <CoreLoader />
              </div>
            )}
            <Switch>
              <Route path={paths.banking.sendBankTransfer()} exact component={BankTransfer} />
              <Route path={paths.banking.sendCrypto()} exact component={CryptoTransfer} />
              <Route path={paths.banking.sendDigitalWallet()} exact component={DigitalWalletTransfer} />
              <Route path={paths.banking.sendTransfer()} exact component={InternalTransfer} />
              <Route path={paths.banking.sendIntraAccountTransfer()} exact component={IntraAccountTransfer} />
              <Route path={paths.banking.sendPix()} exact component={PixTransfer} />
              <Route path={paths.banking.sendPerfectMoney()} exact component={PerfectMoneyTransfer} />
              <Route path={paths.banking.sendInterac()} exact component={InteractTransfer} />

              <Redirect to={paths.banking.sendBankTransfer()} />
            </Switch>
          </div>
        </div>
      )}
    </div>
  )
}

const subFlows = (subFlow: WithdrawalSubFlow) => {
  switch (subFlow) {
    case WithdrawalSubFlowEnum.BANKWIRE:
      return {
        paymentMethod: WithdrawalSubFlowEnum.BANKWIRE as PaymentMethod,
        title: tr('FRONTEND.SEND_MONEY.BANK_TRANSFER_LABEL', 'Bank Transfer'),
        description: tr('FRONTEND.SEND_MONEY.BANK_TRANSFER_DESC', 'Send funds'),
        path: paths.banking.sendBankTransfer(),
        dataTest: 'bank-transfer-button'
      }

    case WithdrawalSubFlowEnum.CRYPTO:
      return {
        paymentMethod: WithdrawalSubFlowEnum.CRYPTO as PaymentMethod,
        title: tr('FRONTEND.SEND_MONEY.CRYPTO_LABEL', 'Send Crypto'),
        description: tr('FRONTEND.SEND_MONEY.CRYPTO_DESCRIPTION', "Send crypto to a recipient's address"),
        path: paths.banking.sendCrypto(),
        dataTest: 'send-crypto-button'
      }

    case WithdrawalSubFlowEnum.DIGITAL_WALLET:
      return {
        paymentMethod: WithdrawalSubFlowEnum.DIGITAL_WALLET as PaymentMethod,
        title: tr('FRONTEND.DIGITAL_WALLET.LABEL', 'Jeton Wallet'),
        description: tr('FRONTEND.DIGITAL_WALLET.DESCRIPTION', 'Send money to Jeton Wallet'),
        path: paths.banking.sendDigitalWallet(),
        dataTest: 'digital-wallet-button'
      }

    case WithdrawalSubFlowEnum.INTERNAL_TRANSFER:
      return {
        paymentMethod: WithdrawalSubFlowEnum.INTERNAL_TRANSFER as PaymentMethod,
        title: tr('FRONTEND.SEND_MONEY.USER_LABEL', 'Kingdom User'),
        description: tr('FRONTEND.SEND_MONEY.USER_DESCRIPTION', 'Send funds to another Kingdom Bank user'),
        path: paths.banking.sendTransfer(),
        dataTest: 'internal-transfer-button'
      }

    case WithdrawalSubFlowEnum.INTRA_ACCOUNT_TRANSFER:
      return {
        paymentMethod: WithdrawalSubFlowEnum.INTRA_ACCOUNT_TRANSFER as PaymentMethod,
        title: tr('FRONTEND.SEND_MONEY.OWN_ACCOUNTS.LABEL', 'Between Own Accounts'),
        description: tr('FRONTEND.SEND_MONEY.OWN_ACCOUNTS.DESCRIPTION', 'Transfer funds between your accounts'),
        path: paths.banking.sendIntraAccountTransfer(),
        dataTest: 'transfer-between-accounts-button'
      }

    case WithdrawalSubFlowEnum.PIX:
      return {
        paymentMethod: WithdrawalSubFlowEnum.PIX as PaymentMethod,
        title: tr('FRONTEND.WITHDRAW.PIX.TITLE', 'PIX Transfer'),
        description: tr('FRONTEND.WITHDRAW.PIX.DESCRIPTION', 'Send money to a PIX account'),
        path: paths.banking.sendPix(),
        dataTest: 'pix-transfer-button'
      }

    case WithdrawalSubFlowEnum.PERFECT_MONEY:
      return {
        paymentMethod: WithdrawalSubFlowEnum.PERFECT_MONEY as PaymentMethod,
        title: tr('FRONTEND.WITHDRAW.PERFECT_MONEY.TITLE', 'Perfect Money Transfer'),
        description: tr('FRONTEND.WITHDRAW.PERFECT_MONEY.DESCRIPTION', 'Send money to a Perfect Money account'),
        path: paths.banking.sendPerfectMoney(),
        dataTest: 'perfect-money-transfer-button'
      }

    case WithdrawalSubFlowEnum.INTERAC:
      return {
        paymentMethod: WithdrawalSubFlowEnum.INTERAC as PaymentMethod,
        title: tr('FRONTEND.WITHDRAW.INTERAC.TITLE', 'Interac transfer'),
        description: tr('FRONTEND.WITHDRAW.INTERAC.SUBTITLE', 'Send money to an Interac account'),
        path: paths.banking.sendInterac(),
        dataTest: 'interac-transfer-button'
      }

    case WithdrawalSubFlowEnum.BATCH:
      return {
        paymentMethod: WithdrawalSubFlowEnum.BATCH as PaymentMethod,
        title: tr('FRONTEND.WITHDRAW.BATCH.TITLE', 'Batch Payment'),
        description: tr('FRONTEND.WITHDRAW.BATCH.SUBTITLE', 'Make a one click mass payment'),
        path: paths.banking.sendBatchPayment(),
        dataTest: 'batch-payment-button'
      }

    default:
      return
  }
}

const activePathMap = {
  [paths.banking.sendBankTransfer()]: WithdrawalSubFlowEnum.BANKWIRE,
  [paths.banking.sendCrypto()]: WithdrawalSubFlowEnum.CRYPTO,
  [paths.banking.sendBatchPayment()]: WithdrawalSubFlowEnum.BATCH,
  [paths.banking.sendDigitalWallet()]: WithdrawalSubFlowEnum.DIGITAL_WALLET,
  [paths.banking.sendTransfer()]: WithdrawalSubFlowEnum.INTERNAL_TRANSFER,
  [paths.banking.sendIntraAccountTransfer()]: WithdrawalSubFlowEnum.INTRA_ACCOUNT_TRANSFER,
  [paths.banking.sendPix()]: WithdrawalSubFlowEnum.PIX,
  [paths.banking.sendPerfectMoney()]: WithdrawalSubFlowEnum.PERFECT_MONEY,
  [paths.banking.sendInterac()]: WithdrawalSubFlowEnum.INTERAC
}
