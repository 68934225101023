import './coreStatusView.scss'
import { CashVoucherStatus, SavingStatusEnum, TransactionStatusEnum } from 'mmfintech-commons-types'

interface IStatusProps {
  status: TransactionStatusEnum | SavingStatusEnum | CashVoucherStatus
  fade?: boolean
  size?: 'small' | 'medium'
}

export const CoreStatusView = ({ status, fade, size = 'small' }: IStatusProps) => {
  const opacityValue = fade ? 0.5 : 1
  const parsedStatus = status.toLocaleLowerCase()
  return (
    <div className={`status-wrapper`}>
      <span className={parsedStatus} data-test='core-status-initial-circle'></span>
      <p className={size} data-test='core-status-initial-text' style={{ opacity: opacityValue }}>
        {status.split('_').join(' ').toLocaleLowerCase()}
      </p>
    </div>
  )
}
