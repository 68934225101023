import { useEffect, useState } from 'react'

import { useGetMerchantQuery } from 'mmfintech-backend-api'
import { MerchantAccountTypeEnum, OnboardingStatusEnum } from 'mmfintech-commons-types'

export const useOnboardingStep = () => {
  const { data: merchant } = useGetMerchantQuery()
  const { accountType, onboardingStatus } = merchant || {}
  const [currentOnboardingStep, setCurrentOnboardingStep] = useState<0 | 1 | 2 | 3>(0)

  useEffect(() => {
    if (accountType === MerchantAccountTypeEnum.PROSPECT) {
      switch (onboardingStatus) {
        case OnboardingStatusEnum.NOT_STARTED:
          setCurrentOnboardingStep(0)
          break
        case OnboardingStatusEnum.WAITING_QUESTIONNAIRE:
        case OnboardingStatusEnum.REJECTED_RETRY:
          setCurrentOnboardingStep(1)
          break
        case OnboardingStatusEnum.IN_PROGRESS:
        case OnboardingStatusEnum.UNDER_REVIEW:
        case OnboardingStatusEnum.WAITING_COMPLIANCE_APPROVAL:
        case OnboardingStatusEnum.WAITING_OPS_APPROVAL:
        case OnboardingStatusEnum.WAITING_ADDITIONAL_DOCUMENTS:
        case OnboardingStatusEnum.WAITING_BUSINESS_AGREEMENT_APPROVAL:
          setCurrentOnboardingStep(2)
          break
        case OnboardingStatusEnum.APPROVED:
          setCurrentOnboardingStep(3)
          break
      }
    } else {
      setCurrentOnboardingStep(3)
    }
  }, [onboardingStatus])

  return {
    currentOnboardingStep
  }
}
