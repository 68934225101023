import { useInfiniteScroll } from '@hooks'
import './YourVouchers.scss'
import { useLazySearchVouchersQuery } from 'mmfintech-backend-api'
import { CashVoucherStatusEnum } from 'mmfintech-commons-types'
import { useEffect } from 'react'

import { CoreLoader } from '@components'
import { SingleVoucherContainer } from '../singleVoucher/SingleVoucher'
import { isValidArray } from 'mmfintech-commons'
import { EmptyVoucherPage } from '../emptyVoucherPage'

export const YourVouchers = ({ isActiveVouchers }: { isActiveVouchers: boolean }) => {
  const filter = {
    size: isActiveVouchers ? 5 : 10,
    statuses: isActiveVouchers
      ? [CashVoucherStatusEnum.ACTIVE]
      : [
          CashVoucherStatusEnum.REDEEMED,
          CashVoucherStatusEnum.CANCELLED,
          CashVoucherStatusEnum.REDEMPTION_IN_PROGRESS,
          CashVoucherStatusEnum.EXPIRED
        ]
  }
  const { combinedData, readMore, isFetching, isLoading, scrollMore, resetData } = useInfiniteScroll(
    useLazySearchVouchersQuery,
    {
      filter
    }
  )

  const handleScrollMore = () => {
    const { scrollTop, scrollHeight, clientHeight } = document.childNodes[1] as any
    const isNearBottom = scrollTop + clientHeight >= scrollHeight
    if (isNearBottom) {
      scrollMore()
    }
  }

  useEffect(() => {
    document.addEventListener('scroll', handleScrollMore)
    return () => {
      document.removeEventListener('scroll', handleScrollMore)
    }
  }, [])

  useEffect(() => {
    resetData()
    readMore({ page: 0, size: isActiveVouchers ? 5 : 10 })
  }, [isActiveVouchers])

  return (
    <>
      {isValidArray(combinedData) ? (
        <div className='your-voucher-container'>
          {combinedData.map(data => {
            return (
              <SingleVoucherContainer
                key={data.uuid}
                data={data}
                readMore={readMore}
                isActiveVouchers={isActiveVouchers}
              />
            )
          })}
          {(isLoading || isFetching) && <CoreLoader />}
        </div>
      ) : (
        <EmptyVoucherPage active={isActiveVouchers} />
      )}
    </>
  )
}
