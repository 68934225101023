import { useContext } from 'react'
import { useLocation } from 'react-router-dom'

import { GlobalContext, tr } from 'mmfintech-commons'
import { useGetMerchantQuery, useGetReportQuery } from 'mmfintech-backend-api'

import { CoreButton } from '@components'
import { AddExpenseModal } from '@views/reports/expenses/AddExpenseModal/AddExpenseModal'

import { ReportStateEnum } from 'mmfintech-commons-types'

import AddExpenseIcon from '@images/expenses/add-expense.svg?react'

const actionStatuses: string[] = [ReportStateEnum.NEW, ReportStateEnum.EDIT]

interface AddEditExpenseButtonProps {
  reportId?: number
  reload?: () => void
}

export const AddExpenseButton = ({ reportId, reload }: AddEditExpenseButtonProps) => {
  const { modalHide, modalShow } = useContext(GlobalContext)

  const { data: merchant } = useGetMerchantQuery(null)
  const { merchantId } = merchant || {}

  const { pathname } = useLocation()

  const { data } = useGetReportQuery({ reportId }, { skip: !reportId })
  const { state, merchantId: reportMerchantId } = data || {}

  const handleCreateExpenseModalOpen = () => {
    modalShow({
      header: tr('FRONTEND.EXPENSES.EXPENSE_MODAL.CREATE_NEW_EXPENSE', 'Create New Expense'),
      options: { size: 'large', closeOnEscape: false, closeOnClickOutside: false },
      content: <AddExpenseModal onClose={modalHide} reportId={reportId} reload={reload} />
    })
  }

  if (reportId && merchantId !== reportMerchantId) {
    return null
  }

  if (!(pathname === '/expense-management/reports') && !actionStatuses.includes(state)) {
    return null
  }

  return (
    <>
      <CoreButton
        text={tr('FRONTEND.EXPENSE_MANAGEMENT.ADD_EXPENSE', 'Add Expense')}
        LeftIcon={<AddExpenseIcon />}
        data-test='button-add-expense-modal'
        onClick={handleCreateExpenseModalOpen}
        size='large'
      />
    </>
  )
}
