import { useContext, useEffect } from 'react'

import { formatFloat, GlobalContext, tr } from 'mmfintech-commons'
import {
  responseContainsStaffOverLimitError,
  useCurrencies,
  useWithdrawInitiateMutation,
  useWithdrawPreviewMutation
} from 'mmfintech-backend-api'

import { ErrorDisplay } from 'mmfintech-portal-commons'
import { CreateExpenseRequestModal } from '../../CreateExpenseRequestModal'
import {
  CoreButton,
  SendMoneyAmountRow,
  SendMoneyValueLabel,
  TrustedRecipientCheckbox,
  WarningMessage
} from '@components'

// interface CryptoTransferPreview

export const CryptoTransferPreview = ({ selectedAccount, selectedRecipient, onSubmit, onCancel, formValues }) => {
  const { modalShow } = useContext(GlobalContext)

  const [_, { data: withdrawPreview }] = useWithdrawPreviewMutation({
    fixedCacheKey: 'crypto-transfer-preview'
  })
  const [__, { error: withdrawError, isLoading: withdrawFetching }] = useWithdrawInitiateMutation({
    fixedCacheKey: 'crypto-transfer-initiate'
  })

  const { getCurrencyPrecision } = useCurrencies()

  useEffect(() => {
    if (responseContainsStaffOverLimitError(withdrawError)) {
      modalShow({
        options: { size: 'auto' },
        content: <CreateExpenseRequestModal formValues={formValues} />
      })
    }
  }, [withdrawError])

  const {
    fee,
    feeCurrency,
    // fxRate,
    // fxRateCurrency,
    processingAmount,
    processingCurrency,
    walletAmount,
    walletCurrency,
    trustedRecipient
  } = withdrawPreview || {}

  const { name, cryptoAddress, tag } = selectedRecipient || {}

  const { name: accountName } = selectedAccount || {}

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '3.2rem'
      }}>
      <div className='send-money-container'>
        <div className='send-money-inside-container amount-container mb-2'>
          <div className='main-amount-label'>{tr('FRONTEND.WITHDRAW.SEND_CRYPTO.PREVIEW.AMOUNT', 'Amount')}</div>
          <div className='main-amount-value'>
            <span data-test='amount'>
              {formatFloat(walletAmount, getCurrencyPrecision(walletCurrency))}{' '}
              <span className='main-amount-currency'>{walletCurrency}</span>
            </span>
          </div>

          {fee > 0 && (
            <SendMoneyAmountRow
              label={tr('FRONTEND.WITHDRAW.BANK_TRANSFER.PREVIEW.FEE', 'Fee')}
              value={
                <span>
                  {formatFloat(fee, getCurrencyPrecision(feeCurrency))}{' '}
                  <span className='row-amount-currency'>{feeCurrency}</span>
                </span>
              }
              data-test='fee-amount'
            />
          )}

          <SendMoneyAmountRow
            label={tr('FRONTEND.WITHDRAW.BANK_TRANSFER.PREVIEW.NET_AMOUNT', 'Net amount')}
            value={
              <span>
                {formatFloat(processingAmount, getCurrencyPrecision(processingCurrency))}{' '}
                <span className='row-amount-currency'>{processingCurrency}</span>
              </span>
            }
            data-test='net-amount'
          />
        </div>

        <div className='send-money-inside-container send-money-details'>
          <SendMoneyValueLabel
            label={tr('FRONTEND.BANKING.SEND_MONEY.FROM_ACCOUNT', 'From account')}
            value={accountName}
            dataTest='account-name'
          />
          <SendMoneyValueLabel
            label={tr('FRONTEND.WITHDRAW.BANK_TRANSFER.PREVIEW.TO', 'To')}
            value={name}
            dataTest='recipient-name'
          />
          <SendMoneyValueLabel
            label={tr('FRONTEND.WITHDRAW.SEND_CRYPTO.PREVIEW.ADDRESS', 'Address')}
            value={cryptoAddress}
            dataTest='address'
          />
          <SendMoneyValueLabel
            label={tr('FRONTEND.WITHDRAW.SEND_CRYPTO.PREVIEW.TAG', 'Tag')}
            value={tag}
            dataTest='tag'
          />
        </div>

        <TrustedRecipientCheckbox formValues={formValues} trustedRecipient={trustedRecipient} />

        <div className='mb-2' />
        <ErrorDisplay error={withdrawError} />

        <WarningMessage
          title={tr('FRONTEND.WITHDRAW.SEND_CRYPTO.PREVIEW.PLEASE_NOTE', 'Please note')}
          lines={[
            tr('FRONTEND.WITHDRAW.SEND_CRYPTO.PREVIEW.WARNING1', 'Please verify this information before confirmation.'),
            tr(
              'FRONTEND.WITHDRAW.SEND_CRYPTO.PREVIEW.WARNING2',
              'Executed transactions cannot be cancelled or refunded. Sending coins to a wrong address may result in loss of funds.'
            )
          ]}
        />

        <div className='preview-actions'>
          <CoreButton
            text={tr('FRONTEND.WITHDRAW.SEND_CRYPTO.PREVIEW.BUTTON_SEND', 'Send')}
            onClick={onSubmit}
            isLoading={withdrawFetching}
            className='mb-1'
            data-test='button-send'
            size='large'
            fullWidth
          />
          <CoreButton
            text={tr('FRONTEND.BUTTONS.CANCEL', 'Cancel')}
            variation='tertiary'
            onClick={onCancel}
            disabled={withdrawFetching}
            data-test='button-cancel'
            size='large'
          />
        </div>
      </div>
    </div>
  )
}
