import { useLocation } from 'react-router-dom'

type UseSearchParamsOutput = {
  get: (name: string) => string
  getInt: (name: string) => number
  recipientId: number
}

export const useSearchParams = (): UseSearchParamsOutput => {
  const location = useLocation()
  const search = new URLSearchParams(location.search)

  const get = (name: string): string => (search.has(name) ? search.get(name) : null)

  const getInt = (name: string): number => (search.has(name) ? parseInt(search.get(name)) : 0)

  return {
    get,
    getInt,
    recipientId: getInt('recipientId')
  }
}
