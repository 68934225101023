import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import fileDownload from 'js-file-download'
import ReactTooltip from 'react-tooltip'

import {
  endpoints,
  paths,
  useBatchPaymentQry,
  useCountries,
  useFileDownloader,
  useGetMerchantQuery,
  useWithdrawAccountsQuery
} from 'mmfintech-backend-api'
import {
  CountrySupportedPurposeEnum,
  MerchantAccountTypeEnum,
  WithdrawalSubFlow,
  WithdrawalSubFlowEnum
} from 'mmfintech-commons-types'
import { tr, GlobalContext, translateError, isValidArray, OtpContext } from 'mmfintech-commons'
import { ErrorDisplay } from 'mmfintech-portal-commons'

import { HeaderContext } from '../../../context/HeaderContext'
import {
  ConfirmationModal,
  CoreButton,
  CoreCheckbox,
  CoreLoader,
  CoreSelect,
  FileUploadPane,
  SuccessPane
} from '@components'
import { SelectType } from './SelectType'
import { TemplateButton } from './TemplateButton'
import { BatchPaymentErrors } from './BatchPaymentErrors'
import { BatchPaymentPreview } from './BatchPaymentPreview'
import {
  Instructions
  // , InstructionsButton
} from './Instructions'

import '../SendMoney/sendMoney.scss'

const acceptTypes = ['csv', 'xls', 'xlsx']

const paymentOptionsMap = {
  [WithdrawalSubFlowEnum.BANKWIRE]: 'BANK',
  [WithdrawalSubFlowEnum.CRYPTO]: 'CRYPTO',
  [WithdrawalSubFlowEnum.INTERNAL_TRANSFER]: 'INTERNAL'
}

const batchSteps = [
  {
    step: 1,
    context: tr('FRONTEND.BATCH_PAYMENTS.STEP_UPLOAD_FILE', 'Upload File'),
    subtitle: ''
  },
  {
    step: 2,
    context: tr('FRONTEND.BATCH_PAYMENTS.STEP_REVIEW', 'Review & Pay'),
    subtitle: ''
  }
]

export const BatchPayment = () => {
  const { modalShow } = useContext(GlobalContext)
  const { data: merchant } = useGetMerchantQuery(null)
  const { setOtpOnSuccess }: any = useContext(OtpContext)
  const [isAbleToBatch, setIsAbleToBatch] = useState(false)

  const { accountType } = merchant || {}
  const isProspect = accountType === MerchantAccountTypeEnum.PROSPECT
  const [type, setType] = useState<WithdrawalSubFlow>(WithdrawalSubFlowEnum.BANKWIRE)
  const [selectedAccount, setSelectedAccount] = useState(null)
  const [selectedCountry, setSelectedCountry] = useState<string[]>([])
  const [instructionsVisible, setInstructionsVisible] = useState<boolean>(false)

  const history = useHistory()

  const { countryOptions, countriesFetching } = useCountries(CountrySupportedPurposeEnum.WITHDRAWAL)
  const { setCurrentStep, currentStep, setTotalSteps } = useContext(HeaderContext)

  const {
    data: accounts,
    error: accountsError,
    isFetching: accountsFetching,
    isLoading: accountsLoading
  } = useWithdrawAccountsQuery(type)

  useEffect(() => {
    setType(isProspect ? WithdrawalSubFlowEnum.CRYPTO : WithdrawalSubFlowEnum.BANKWIRE)
  }, [isProspect])

  const {
    file,
    fileError,
    fileId,
    setFileId,
    fileValid,
    setFileValid,
    handleFileError,
    handleFileReset,
    handleFileSelected,
    previewData: batchPaymentsUpload,
    previewError: batchPaymentsUploadError,
    previewFetching: batchPaymentsUploadFetching,
    initiateError,
    // initiateFetching,
    uploadBatchFile,
    initiatePayment,
    resetMutations
  } = useBatchPaymentQry()

  const template = useFileDownloader({})

  const getCountryOptions = () =>
    selectedAccount && isValidArray(selectedAccount.supportedCountries)
      ? countryOptions?.filter(node => selectedAccount.supportedCountries.includes(node.value))
      : []

  useEffect(() => {
    setSelectedCountry([])
  }, [selectedAccount])

  // const toggleInstructions = () => setInstructionsVisible(prevState => !prevState)

  const handleDownload = (xls: boolean) => {
    void template.download({
      url: endpoints.batchWithdrawal.downloadTemplate(paymentOptionsMap[type]),
      method: 'POST',
      data: {
        accountId: selectedAccount.id,
        ...(type === WithdrawalSubFlowEnum.BANKWIRE ? { countryCodes: selectedCountry } : null),
        xls
      },
      onSuccess: (data: any, filename: string) => {
        fileDownload(data, filename || `batch-payment-template.${xls ? 'xlsx' : 'csv'}`)
        setIsAbleToBatch(true)
      }
    })
  }

  const handleOpenConfirmation = () => {
    modalShow({
      header: 'Confirmation',
      content: (
        <ConfirmationModal
          question={tr(
            'FRONTEND.BATCH_PAYMENTS.ERROR_DUPLICATE',
            'This is a duplicate batch file. Proceeding with the upload will perform the transactions twice. Are you sure you want to proceed?'
          )}
          onConfirm={() => handleContinue(true)}
        />
      )
    })
  }

  const handleContinue = async (allowDuplicate: boolean) => {
    if (valid() && file) {
      setCurrentStep(2)

      try {
        await uploadBatchFile({
          accountId: selectedAccount.id,
          type: paymentOptionsMap[type],
          file,
          allowDuplicates: allowDuplicate
        }).unwrap()
        setCurrentStep(3)
      } catch (error) {}
    }
  }

  const valid = () => !!selectedAccount && (selectedCountry?.length > 0 || type !== WithdrawalSubFlowEnum.BANKWIRE)

  useEffect(() => {
    setTotalSteps(batchSteps)

    return () => {
      setSelectedAccount(null)
      setSelectedCountry(null)
      setInstructionsVisible(false)
      handleFileReset()
      setCurrentStep(1)
    }
  }, [])

  useEffect(() => {
    return () => {}
  }, [])

  useEffect(() => {
    setSelectedAccount(null)
  }, [type])

  useEffect(() => {
    if (batchPaymentsUpload) {
      const rowsValid = batchPaymentsUpload.every(({ rowValid, previewValid }) => rowValid && previewValid)
      if (rowsValid) {
        setFileValid(true)
        setFileId(batchPaymentsUpload[0].fileId)
      }
    }
    // eslint-disable-next-line
  }, [batchPaymentsUpload])

  useEffect(() => {
    if (batchPaymentsUploadError) {
      setFileValid(false)
      if (
        'errorKey' in batchPaymentsUploadError &&
        batchPaymentsUploadError.errorKey === 'MERCHANTS.ERROR.DUPLICATE_BATCH_FILE'
      ) {
        handleOpenConfirmation()
      } else {
        setCurrentStep(3)
      }
    }
  }, [batchPaymentsUploadError])

  useEffect(() => {
    return () => {
      resetMutations()
    }
    // eslint-disable-next-line
  }, [])

  const handleInitiate = async () => {
    setOtpOnSuccess(() => () => {
      setCurrentStep(4)
    })
    try {
      await initiatePayment({ fileId }).unwrap()
    } catch {}
  }

  return (
    <div className='send-money-wrapper'>
      <div className='send-wrapper'>
        <SelectType
          type={type}
          setType={setType}
          isProspect={isProspect}
          loading={accountsFetching || accountsLoading}
          currentStep={currentStep}
        />

        {currentStep === 1 && (
          <div className='send-content-wrapper'>
            {(accountsLoading || accountsFetching) && (
              <div className='accounts-loader'>
                <CoreLoader />
              </div>
            )}
            <div className='send-money-container'>
              <div className='batch-payment-step'>
                <span>1. {tr('FRONTEND.BATCH_PAYMENTS.SECTION.TEMPLATE', 'Download Template')}</span>
                {/* TODO: Still in discussion in we need instructions  */}
                {/* <InstructionsButton onClick={toggleInstructions} /> */}
              </div>
              {instructionsVisible ? <Instructions /> : null}
              <div className='mb-2'>
                <CoreSelect
                  type='account'
                  options={accounts}
                  label={
                    <>
                      {tr('FRONTEND.BATCH_PAYMENTS.FORM.ACCOUNT', 'Account')}
                      <span className='asterisk'>*</span>
                    </>
                  }
                  value={selectedAccount}
                  onChange={(_name, value) => {
                    setSelectedAccount(value)
                  }}
                  allAccounts
                  loading={accountsFetching}
                  error={translateError(accountsError)}
                />
              </div>
              {type === WithdrawalSubFlowEnum.BANKWIRE && (
                <CoreSelect
                  type='country'
                  name='countries'
                  value={selectedCountry}
                  options={getCountryOptions()}
                  data-test='file-type'
                  label={tr('FRONTEND.BATCH_PAYMENTS.FORM.COUNTRY', 'Destination Country for Transfers')}
                  onChange={(_name: any, value: any) =>
                    setSelectedCountry(typeof value === 'string' ? [...selectedCountry, value] : value)
                  }
                  loading={countriesFetching}
                  isMultiply
                  required
                />
              )}
              <div className='templates-wrapper'>
                <div className='templates-wrapper-left' data-for='tooltip-template'>
                  <TemplateButton
                    text={tr('FRONTEND.BATCH_PAYMENTS.TEMPLATE_XLS', 'Excel Table (.xls)')}
                    dataTest='download-xls-template'
                    onClick={() => handleDownload(true)}
                    disabled={!valid() || template.fetching}
                  />
                  <TemplateButton
                    text={tr('FRONTEND.BATCH_PAYMENTS.TEMPLATE_CSV', 'Text File (.csv)')}
                    dataTest='download-csv-template'
                    onClick={() => handleDownload(false)}
                    disabled={!valid() || template.fetching}
                  />
                </div>

                <div className='checkbox-wrapper'>
                  <CoreCheckbox
                    type='check'
                    data-test={`is-having-file`}
                    checked={isAbleToBatch}
                    value={'have-file'}
                    onClick={() => setIsAbleToBatch(!isAbleToBatch)}
                  />
                  <span>I already have a file </span>{' '}
                </div>
              </div>
              <ErrorDisplay error={template.error} />
              <div className='batch-payment-step'>
                <span>2. {tr('FRONTEND.BATCH_PAYMENTS.SECTION.UPLOAD_FILE', 'Upload File')}</span>
              </div>
              <FileUploadPane
                disabled={!valid()}
                acceptTypes={acceptTypes}
                file={file}
                onDrop={handleFileSelected}
                onError={handleFileError}
                onRemove={handleFileReset}
                dragAndDropMessage={tr('FRONTEND.BATCH_PAYMENTS.DRAG_AND_DROP', 'Drag & Drop {{FILE_TYPE}} Template', {
                  FILE_TYPE: tr(`FRONTEND.BATCH_PAYMENTS.TYPE_${paymentOptionsMap[type]}`, paymentOptionsMap[type])
                })}
                dragAndDropHint={tr(
                  'FRONTEND.BATCH_PAYMENTS.DRAG_AND_DROP_HINT',
                  '*File format: *.xls, *.xlsx, *.csv (maximum file size 2MB)'
                )}
              />
              <ErrorDisplay error={[fileError, initiateError]} />
              <div className='mb-4' />
              <CoreButton
                type='button'
                text={tr('FRONTEND.BUTTONS.CONTINUE', 'Continue')}
                className='mb-1'
                data-test='continue-button'
                disabled={!file || !isAbleToBatch}
                isLoading={batchPaymentsUploadFetching}
                onClick={() => handleContinue(false)}
                size='large'
                fullWidth
              />
            </div>
          </div>
        )}

        {currentStep === 2 && (
          <div className='send-money-container'>
            <div className='batch-loading-wrapper'>
              <div className='loader-container'>
                <CoreLoader />
                <div className='wait-text'>{tr('FRONTEND.BATCH_PAYMENTS.WAIT.TITLE', 'Please wait')}</div>
              </div>
              <div>{tr('FRONTEND.BATCH_PAYMENTS.WAIT.MESSAGE', 'Checking payments data...')}</div>
            </div>
          </div>
        )}

        {currentStep === 3 ? (
          fileValid ? (
            <BatchPaymentPreview
              account={selectedAccount}
              file={file}
              onSubmit={handleInitiate}
              onCancel={() => {
                handleFileReset()
                setCurrentStep(1)
              }}
            />
          ) : (
            <BatchPaymentErrors
              onReload={() => {
                handleFileReset()
                setCurrentStep(1)
              }}
            />
          )
        ) : null}

        {currentStep === 4 && <SuccessPane onClick={() => history.push(paths.banking.transactions.list())} />}
        <ReactTooltip
          id='tooltip-template'
          type='dark'
          effect='solid'
          place='bottom'
          getContent={() =>
            valid() ? null : (
              <div style={{ maxWidth: '23rem' }}>
                {tr(
                  'FRONTEND.BATCH_PAYMENTS.INVALID_HINT',
                  'First select the account and destination countries, to generate the appropriate template'
                )}
              </div>
            )
          }
        />
      </div>
    </div>
  )
}
