import { useHistory } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'

import { paths, useGetMerchantQuery } from 'mmfintech-backend-api'
import { MerchantAccountTypeEnum } from 'mmfintech-commons-types'
import { GlobalContext, tr } from 'mmfintech-commons'

import { CoreButton } from '@components'

import ToastSuccess from '@images/icons/success-toaster-icon.svg?react'
import WelcomeIcon2 from '@images/icons/welcome_2.svg?react'
import CrossIcon from '@images/icons/cross-icon.svg?react'

import './welcomeContent2.scss'

function isSameDayAndMonth(createdOn) {
  const createdOnDate = new Date(createdOn)

  const today = new Date()

  return createdOnDate.getMonth() === today.getMonth() && createdOnDate.getDate() === today.getDate()
}

type TWelcomeContent2Props = {
  isSuccess2Fa: boolean
}

export const WelcomeContent2 = ({ isSuccess2Fa }: TWelcomeContent2Props) => {
  const history = useHistory()
  const { data: merchant } = useGetMerchantQuery()
  const { accountType, createdOn } = merchant || {}
  const { modalHide } = useContext(GlobalContext)

  const [showSuccess, setShowSuccess] = useState(false)

  if (accountType !== MerchantAccountTypeEnum.PROSPECT || !isSameDayAndMonth(createdOn)) {
    modalHide()
  }

  useEffect(() => {
    let timer

    if (isSuccess2Fa) {
      setShowSuccess(true)

      timer = setTimeout(() => {
        setShowSuccess(false)
      }, 2000)
    } else {
      setShowSuccess(false)
    }

    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [isSuccess2Fa])

  return (
    <>
      <div className='success-2fa'>
        {showSuccess && (
          <div className='success-2fa-content'>
            <ToastSuccess />
            <span>{tr('FRONTEND.WELCOME.MODAL.STEP_2.SUCCESS', 'Two-factor authentication method changed!')}</span>
          </div>
        )}
      </div>
      <div className='welcome-step-2'>
        <div className='logo'>
          <WelcomeIcon2 />
        </div>
        <div className='title'>
          {tr('FRONTEND.WELCOME.MODAL.STEP_2.TITLE', 'You can now deposit, exchange and send your CRYPTO on the go!')}
        </div>
        <CoreButton
          variation='elevated'
          text={tr('FRONTEND.WELCOME.MODAL.STEP_2.DEPOSIT_CRYPTO', 'Deposit Crypto')}
          onClick={() => history.push(paths.banking.deposit())}
          size='large'
          LeftIcon={<CrossIcon />}
        />
        <p className='description'>
          {tr(
            'FRONTEND.WELCOME.MODAL.STEP_2.DESCRIPTION',
            'Or Complete your account to unlock FIAT Services, IBANS and many additional features'
          )}
        </p>
      </div>
    </>
  )
}
