import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { isValidArray, useFormValues } from 'mmfintech-commons'
import {
  api,
  useCreateExternalBankAccountMutation,
  useEditExternalBankAccountMutation,
  useGetExternalBankAccountQuery
} from 'mmfintech-backend-api'
import {
  loadBankAccountIntoFormValues,
  prepareBankAccountCreateRequest,
  prepareBankAccountUpdateRequest,
  useBankMetadata
} from './bankAccountUtils'

interface EditBankAccountProps {
  accountId?: number
  recipientId?: number
  currencyCode?: string
  settlement?: boolean
  enableProvidedBanks?: boolean
  onSuccess?: (response: any) => void
  disableBankMetadata?: boolean
}

// noinspection JSUnusedGlobalSymbols
export const useEditBankAccountQry = ({
  accountId,
  recipientId,
  currencyCode,
  settlement,
  enableProvidedBanks,
  onSuccess,
  disableBankMetadata = false
}: EditBankAccountProps) => {
  const [step, setStep] = useState<number>(1)
  const [countryCode, setCountryCode] = useState<string>(null)

  const formValues = useFormValues(
    {
      currency: { required: !disableBankMetadata, value: currencyCode, step: 1 }, // added disableBankMetadata
      name: { required: true, validation: 'name', step: 1 },
      description: { maxLength: 100, validation: 'safe-string', step: 1 }
    },
    step,
    setStep
  )

  const dispatch = useDispatch()

  const { data: recipient } = useGetExternalBankAccountQuery(
    { paymentInstrumentId: recipientId },
    {
      refetchOnMountOrArgChange: true,
      skip: !recipientId
    }
  )

  const {
    bankMetadata,
    bankMetadataError,
    bankMetadataFetching,
    accountTypeOptions,
    documentTypes,
    documentTypesOptions,
    documentTypesFetching,
    documentTypesError,
    providedBanks,
    initializeFormValues
  } = useBankMetadata({
    countryCode,
    currencyCode: formValues.getValue('currency') || currencyCode,
    accountId,
    settlement: recipient ? recipient.settlement : settlement,
    enableProvidedBanks
  })

  const [createRecipient, { error: createError, isLoading: createLoading }] = useCreateExternalBankAccountMutation()
  const [updateRecipient, { error: updateError, isLoading: updateLoading }] = useEditExternalBankAccountMutation()

  const finalAction = (response: any): void => {
    typeof onSuccess === 'function' && onSuccess(response)
  }

  const handleSubmit = async (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault()

    if ((!bankMetadataFetching || disableBankMetadata) && formValues.areValid()) {
      // added disableBankMetadata
      try {
        let response: any
        if (recipientId > 0) {
          response = await updateRecipient({
            paymentInstrumentId: recipientId,
            payload: prepareBankAccountUpdateRequest(
              formValues,
              accountId,
              countryCode,
              disableBankMetadata ? null : formValues.getValue('currency'), // added disableBankMetadata
              settlement
            )
          }).unwrap()
        } else {
          response = await createRecipient(
            prepareBankAccountCreateRequest(
              formValues,
              accountId,
              countryCode,
              formValues.getValue('currency'),
              settlement
            )
          ).unwrap()
        }
        finalAction(response)
      } catch (error) {
        return { error }
      }
    }
  }

  const loadRecipientIntoFormValues = () => {
    if (recipientId > 0 && recipient) {
      loadBankAccountIntoFormValues(formValues, recipient)
      if (recipient.country !== countryCode) {
        setCountryCode(recipient.country)
      }
    }
  }

  useEffect(() => {
    return () => {
      setStep(1)
      setCountryCode(null)
    }
  }, [])

  useEffect(() => {
    if (!recipientId) {
      dispatch(api.util.invalidateTags(['ExternalBankAccount']))
    }
  }, [recipientId])

  useEffect(() => {
    if (isValidArray(bankMetadata)) {
      initializeFormValues(formValues)
      // loadRecipientIntoFormValues()
    }
    // eslint-disable-next-line
  }, [bankMetadata])

  useEffect(() => {
    loadRecipientIntoFormValues()
  }, [recipientId, recipient])

  useEffect(() => {
    formValues.handleErrors(recipientId ? updateError : createError, {
      holderName: 'name'
    })
    // eslint-disable-next-line
  }, [createError, updateError])

  return {
    step,
    setStep,
    formValues,
    countryCode,
    setCountryCode,
    bankMetadata,
    bankMetadataError,
    bankMetadataFetching,
    accountTypeOptions,
    documentTypes,
    documentTypesOptions,
    documentTypesFetching,
    documentTypesError,
    providedBanks,
    handleSubmit,
    saveRecipientError: createError || updateError,
    saveRecipientLoading: createLoading || updateLoading
  }
}
