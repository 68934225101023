import { useHistory } from 'react-router-dom'

import { paths, useGetMerchantQuery } from 'mmfintech-backend-api'

import { AccessDenied, CoreLoader, CoreSwitch, CoreTabs, SuccessPane } from '@components'
import './CashVoucher.scss'
// import { RedeemVoucherTab } from './redeemVoucherTab/RedeemVoucherTab'

import { SupportBlock } from '@views/banking/Deposit/elements/SupportBlock'
import { tr } from 'mmfintech-commons'
import { useEffect, useState } from 'react'

import { BuyVoucherTab } from './buyVoucherTab/BuyVoucherTab'
import { YourVouchers } from './yourVouchers/YourVouchers'
import { IsCollapsedMenu } from '@utils'
import cn from 'classnames'

const VOUCHER_TABS = [
  {
    label: tr('FRONTEND.PROFILE.PROFILE_DETAILS_TITLE', 'Buy New Voucher'),
    value: 'buy',
    path: paths.more.cashVoucher('buy')
  },
  {
    label: tr('FRONTEND.SECURITY.YOUR VOUCHERS', 'Your Vouchers'),
    value: 'list',
    path: paths.more.cashVoucher('list')
  }
]

const CashVoucher = () => {
  const { data: merchant } = useGetMerchantQuery(null)
  const history = useHistory()

  const { pathname, state } = history?.location
  const endPath = pathname.split('/')[3]
  const [activeTab, setActiveTab] = useState(endPath)
  const [successPane, setSuccessPane] = useState(false)
  const [isActiveVouchers, setIsActiveVouchers] = useState(true)
  const [collapsedMenu, setCollapsedMenu] = useState(false)

  const { capabilities } = merchant || {}

  useEffect(() => {
    if (endPath) {
      setActiveTab(endPath)
    }
  }, [endPath])

  const onChangeTab = value => {
    setActiveTab(value)
    history.push(VOUCHER_TABS.find(x => x.value === value).path, endPath)
  }

  if (!merchant) {
    return (
      <div className='cash-voucher-loader'>
        <CoreLoader />
      </div>
    )
  }
  if (capabilities && !capabilities.enableCashVouchers) {
    return <AccessDenied />
  }
  return (
    <div className='cash-voucher-container'>
      <IsCollapsedMenu setCollapsedMenu={setCollapsedMenu} />
      <div className='navigation-tabs'>
        <CoreTabs tabs={VOUCHER_TABS} onChangeTab={onChangeTab} tabState={activeTab} initial={state as string} />

        {activeTab === 'list' && (
          <div className='switch-active-tabs'>
            <span>History</span>
            <CoreSwitch handleSwitch={() => setIsActiveVouchers(!isActiveVouchers)} isActive={isActiveVouchers} />
            <span>Active</span>
          </div>
        )}
      </div>
      <div className={cn('cash-voucher-content-container', { 'collapsed-menu': !collapsedMenu })}>
        {activeTab === 'buy' && (
          <div className='cash-voucher-wrapper'>
            {successPane ? (
              <SuccessPane
                message={<SuccessMessage />}
                confirmMessage={tr('FRONTEND.CASH_VOUCHERS.SUCCESS_PANE.CONFIRMATION_BUTTON', 'Buy Another One')}
                onClick={() => {
                  setSuccessPane(false)
                  setActiveTab('buy')
                }}
              />
            ) : (
              <div className='cash-voucher-content'>
                <BuyVoucherTab onSuccess={() => setSuccessPane(true)} />
              </div>
            )}
            <SupportBlock />
          </div>
        )}
        {activeTab === 'list' && <YourVouchers isActiveVouchers={isActiveVouchers} />}
      </div>
    </div>
  )
}

export default CashVoucher

const SuccessMessage = () => {
  return (
    <div className='cash-voucher-success-pane'>
      <span className='cash-voucher-success-pane-title'>
        {tr('FRONTEND.CASH_VOUCHERS.SUCCESS_PANE.TITLE', 'Voucher purchased successfully!')}
      </span>
      <span className='cash-voucher-success-pane-sub-title'>
        {tr('FRONTEND.CASH_VOUCHERS.SUCCESS_PANE.SUB_TITLE', 'Go to Your Vouchers tab to view it.')}
      </span>
    </div>
  )
}
