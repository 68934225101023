import { useEffect } from 'react'
import { Link } from 'react-router-dom'

import settings from '@settings'

import { useCountries, useGetCardBootstrapDataQuery, useGetProfileQuery } from 'mmfintech-backend-api'
import { isValidArray, isValidObject, tr } from 'mmfintech-commons'
import { CountrySupportedPurposeEnum, MerchantEntityTypeEnum } from 'mmfintech-commons-types'
import { ErrorDisplay, Spinner } from 'mmfintech-portal-commons'

import './cardOrderModal.scss'
import { CoreInput, CoreSelect } from '@components'

export const CardOrderForm = ({ cardType, formValues, cardIssueLoading, entityType, changePhoneError }) => {
  const { countryOptions } = useCountries(CountrySupportedPurposeEnum.REGISTRATION)
  const {
    data: bootstrapData,
    isLoading,
    error: bootstrapDataError
  } = useGetCardBootstrapDataQuery({ isVirtual: cardType === 'virtual' })

  const { hasValidatedPhone, phoneCheckLoading, phoneCheckError } = useGetProfileQuery(null, {
    selectFromResult: ({ data, isLoading, error }) => {
      return {
        hasValidatedPhone: !!data?.phone3dsVerified,
        phoneCheckError: error,
        phoneCheckLoading: isLoading
      }
    }
  })

  useEffect(() => {
    if (formValues && bootstrapData) {
      Object.entries(bootstrapData).forEach(el => {
        const [key, value] = el
        if (!Array.isArray(value) && !isValidObject(value)) {
          formValues.setValue(key, value)
        }
        if (Array.isArray(value) && value.length === 1 && key === 'companyNameSuggestions') {
          formValues.setValue('companyName', value[0])
        }
      })
      if (bootstrapData.address) {
        Object.entries(bootstrapData.address).forEach(el => {
          const [key, value] = el
          if (key === 'addressLine1') {
            formValues.setValue('street', value)
          }
          formValues.setValue(key, value)
        })
      }
    }
  }, [bootstrapData])

  if (isLoading || phoneCheckLoading || cardIssueLoading) {
    return <Spinner />
  }

  return (
    <div className='card-order-form-wrapper'>
      <div className='card-order-form-title'>
        {tr('FRONTEND.CARDS.ORDER.PHONE_NUMBER.TITLE', 'Please provide a verification phone number')}
      </div>
      <CoreInput
        type='phone'
        label={tr('FRONTEND.DASHBOARD.CARDS_ORDER.LABEL.PHONE_NUMBER', 'Phone number')}
        {...formValues.registerInput('phoneNumber')}
        disabled={hasValidatedPhone}
      />
      {entityType === MerchantEntityTypeEnum.COMPANY && isValidObject(bootstrapData) && (
        <>
          <div className='card-order-form-title'>
            {tr('FRONTEND.CARDS.ORDER.COMPANY_DETAILS.TITLE', 'Please provide your company details')}
          </div>
          <div className='card-order-form-inputs'>
            {isValidArray(bootstrapData?.companyNameSuggestions) &&
            bootstrapData?.companyNameSuggestions?.length == 1 ? (
              <CoreInput
                disabled
                label={tr('FRONTEND.CARDS.ORDER.COMPANY_DETAILS.NAME', 'Company Name')}
                value={bootstrapData?.companyNameSuggestions?.[0]}
                autoComplete='off'
              />
            ) : (
              <CoreSelect
                label={tr('FRONTEND.CARDS.ORDER.COMPANY_DETAILS.NAME', 'Company Name')}
                options={bootstrapData?.companyNameSuggestions?.map(el => {
                  return { label: el, value: el }
                })}
                {...formValues.registerInput('companyName')}
                autoComplete='off'
              />
            )}
          </div>
          {!bootstrapData?.firstName && (
            <CoreInput
              type='text'
              label={tr('FRONTEND.CARDS.ORDER.COMPANY_DETAILS.FIRST_NAME', 'First Name')}
              {...formValues.registerInput('firstName')}
              autoComplete='off'
            />
          )}
          {!bootstrapData?.lastName && (
            <CoreInput
              type='text'
              label={tr('FRONTEND.CARDS.ORDER.COMPANY_DETAILS.LAST_NAME', 'Last Name')}
              {...formValues.registerInput('lastName')}
              autoComplete='off'
            />
          )}
        </>
      )}
      {cardType === 'physical' && (
        <>
          <div className='card-order-form-title'>
            {tr('FRONTEND.DASHBOARD.CARDS_ORDER.FORM.TITLE', 'Confirm Your Delivery Address')}
          </div>
          <div className='card-order-form-subtitle'>
            {tr(
              'FRONTEND.DASHBOARD.CARDS_ORDER.FORM.SUBTITLE',
              'Your contactless debit card will be sent to the address below. Please verify or update the address if needed.'
            )}
          </div>
          <div className='card-order-form-inputs'>
            <CoreSelect
              type='country'
              label={tr('FRONTEND.DASHBOARD.CARDS_ORDER.LABEL.COUNTRY', 'Country')}
              options={countryOptions}
              {...formValues.registerInput('countryCode')}
            />
            <CoreInput
              type='text'
              label={tr('FRONTEND.DASHBOARD.CARDS_ORDER.LABEL.CITY', 'City')}
              hint={tr('FRONTEND.DASHBOARD.CARDS_ORDER.LABEL.CITY_HINT', 'Please use only Latin letters')}
              {...formValues.registerInput('city')}
            />
            <CoreInput
              type='text'
              label={tr('FRONTEND.DASHBOARD.CARDS_ORDER.LABEL.POST_CODE', 'Post code')}
              {...formValues.registerInput('postalCode')}
            />
            <CoreInput
              type='text'
              label={tr('FRONTEND.DASHBOARD.CARDS_ORDER.LABEL.ADDRESS', 'Street name')}
              hint={tr('FRONTEND.DASHBOARD.CARDS_ORDER.LABEL.ADDRESS_HINT', 'Please use only Latin letters')}
              {...formValues.registerInput('street')}
            />
            <CoreInput
              type='text'
              label={tr('FRONTEND.DASHBOARD.CARDS_ORDER.LABEL.STREET_NUMBER', 'Street number')}
              {...formValues.registerInput('streetNumber')}
            />
          </div>
        </>
      )}
      <ErrorDisplay error={[bootstrapDataError, phoneCheckError, changePhoneError]} />
      <div className='card-order-form-footer'>
        {tr(
          'FRONTEND.DASHBOARD.CARDS_ORDER.FORM.TERMS_AGREEMENT',
          'By tapping Accept and complete order, you have read and accept our'
        )}
        <Link to={{ pathname: settings.landingPageUrl + '/terms-and-conditions' }} target='_blank' data-test='TOC'>
          <span className='ml-2 mr-2'>{tr('FRONTEND.DASHBOARD.CARDS_ORDER.FORM.TOC', 'Terms and Conditions')}</span>
        </Link>
        {tr('FRONTEND.DASHBOARD.CARDS_ORDER.FORM.AND', 'and')}
        <Link to={{ pathname: settings.landingPageUrl + '/privacy-policy' }} target='_blank' data-test='privacy'>
          <span className='ml-2'>{tr('FRONTEND.DASHBOARD.CARDS_ORDER.FORM.PRIVACY', 'Privacy Policy')}</span>
        </Link>
      </div>
    </div>
  )
}
