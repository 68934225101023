import {
  useCurrencies,
  useInternalTransferInitiateMutation,
  useInternalTransferPreviewMutation
} from 'mmfintech-backend-api'
import { AccountBalanceOut } from 'mmfintech-commons-types'
import { formatFloat, tr } from 'mmfintech-commons'
import { ErrorDisplay } from 'mmfintech-portal-commons'

import { CoreButton, SendMoneyAmountRow, SendMoneyValueLabel, TrustedRecipientCheckbox } from '@components'

interface InternalTransferPreviewProps {
  selectedAccount?: AccountBalanceOut
  formValues?: any
  email?: string
  onSubmit: () => void
  onCancel: () => void
}

export const InternalTransferPreview = ({
  selectedAccount,
  formValues,
  email,
  onSubmit,
  onCancel
}: InternalTransferPreviewProps) => {
  const [_, { data: transferPreview }] = useInternalTransferPreviewMutation({
    fixedCacheKey: 'internal-transfer-preview'
  })
  const [__, { error: transferError, isLoading: transferLoading }] = useInternalTransferInitiateMutation({
    fixedCacheKey: 'internal-transfer-initiate'
  })

  const { getCurrencyPrecision } = useCurrencies()

  const { fee, feeCurrency, processingAmount, processingCurrency, walletAmount, walletCurrency, trustedRecipient } =
    transferPreview || {}

  const { name: accountName } = selectedAccount || {}

  return (
    <div className='send-money-container'>
      <div className='send-money-inside-container amount-container mb-2'>
        <div className='main-amount-label'>{tr('FRONTEND.WITHDRAW.BANK_TRANSFER.PREVIEW.AMOUNT', 'Amount')}</div>
        <div className='main-amount-value'>
          <span data-test='amount'>
            {formatFloat(walletAmount, getCurrencyPrecision(walletCurrency))}{' '}
            <span className='main-amount-currency'>{walletCurrency}</span>
          </span>
        </div>

        {fee > 0 && (
          <SendMoneyAmountRow
            label={tr('FRONTEND.WITHDRAW.BANK_TRANSFER.PREVIEW.FEE', 'Fee')}
            value={
              <span>
                {formatFloat(fee, getCurrencyPrecision(feeCurrency))}{' '}
                <span className='row-amount-currency'>{feeCurrency}</span>
              </span>
            }
            data-test='fee-amount'
          />
        )}

        <SendMoneyAmountRow
          label={tr('FRONTEND.WITHDRAW.BANK_TRANSFER.PREVIEW.NET_AMOUNT', 'Net amount')}
          value={
            <span>
              {formatFloat(processingAmount, getCurrencyPrecision(processingCurrency))}{' '}
              <span className='row-amount-currency'>{processingCurrency}</span>
            </span>
          }
          data-test='net-amount'
        />
      </div>

      <div className='send-money-inside-container send-money-details'>
        <SendMoneyValueLabel
          label={tr('FRONTEND.WITHDRAW.BANK_TRANSFER.PREVIEW.FROM', 'From account')}
          value={accountName}
          dataTest='account-name'
        />
        <SendMoneyValueLabel
          label={tr('FRONTEND.TRANSFER.PREVIEW.EMAIL', 'Recipient email')}
          value={email || formValues.getValue('recipientEmail')}
          dataTest='recipient-email'
        />
        <SendMoneyValueLabel
          label={tr('FRONTEND.TRANSFER.PREVIEW.REFERENCE', 'Reference')}
          value={formValues.getValue('reference')}
          dataTest='reference'
        />
      </div>

      <TrustedRecipientCheckbox formValues={formValues} trustedRecipient={trustedRecipient} />

      <ErrorDisplay error={transferError} />

      <div className='preview-actions'>
        <CoreButton
          text={tr('FRONTEND.WITHDRAW.BANK_TRANSFER.PREVIEW.BUTTON_SEND', 'Send')}
          onClick={onSubmit}
          isLoading={transferLoading}
          disabled={transferLoading}
          className='mb-1'
          data-test='button-send'
          size='large'
          fullWidth
        />
        <CoreButton
          text={tr('FRONTEND.BUTTONS.CANCEL', 'Cancel')}
          variation='tertiary'
          onClick={onCancel}
          disabled={transferLoading}
          data-test='button-cancel'
          size='large'
        />
      </div>
    </div>
  )
}
