import React from 'react'

import { DragAndDrop, FileInput } from 'mmfintech-portal-commons'

interface UploadedFile {
  fileUrl: string
  type: string
}

interface ExpenseDragAndDropComponent {
  file: UploadedFile
  onFileUpload: () => void
  onRemoveAll: () => void
  removeFile: () => void
  error: unknown
}

interface ExpenseDragAndDropProps {
  file: UploadedFile
  component: React.FC<ExpenseDragAndDropComponent>
  onSelect?: (file: File) => void
  onError?: (errors: any) => void
  onRemove?: () => void
  error?: unknown
}

export const ExpenseDragAndDrop = (props: ExpenseDragAndDropProps) => {
  const { file, component: Component, onError, onSelect, onRemove, error } = props

  const handleSelect = (list: File[] | FileList) => {
    if (list.length) {
      typeof onSelect === 'function' && onSelect(list[0])
    }
  }

  const handleErrors = (errors: any) => typeof onError === 'function' && onError(errors)

  const handleFileRemove = () => typeof onRemove === 'function' && onRemove()

  return (
    <DragAndDrop showDropTarget={true} acceptType={['png', 'jpg', 'pdf']} onDrop={handleSelect} onError={handleErrors}>
      <FileInput
        maxNumber={1}
        // maxFileSize={certificateMaxFileSize}
        acceptType={['png', 'jpg', 'pdf']}
        onChange={handleSelect}
        onError={handleErrors}>
        {({ onFileUpload, onRemoveAll }) => (
          <Component
            file={file}
            onFileUpload={onFileUpload}
            onRemoveAll={onRemoveAll}
            removeFile={handleFileRemove}
            error={error}
          />
        )}
      </FileInput>
    </DragAndDrop>
  )
}
