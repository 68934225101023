import { useContext, useEffect } from 'react'

import { tr, translateCountryName, GlobalContext, formatFloat } from 'mmfintech-commons'
import { responseContainsStaffOverLimitError, useWalletTransferPreviewQry, useCurrencies } from 'mmfintech-backend-api'

import { ErrorDisplay } from 'mmfintech-portal-commons'
import { CreateExpenseRequestModal } from '../../CreateExpenseRequestModal'
import { CoreButton, SendMoneyAmountRow, SendMoneyValueLabel, TrustedRecipientCheckbox } from '@components'

export const WalletTransferPreview = ({ formValues, selectedAccount, selectedRecipient, onSubmit, onCancel }) => {
  const { modalShow } = useContext(GlobalContext)

  const { getCurrencyPrecision } = useCurrencies()

  const { transferPreview, transferError, transferFetching } = useWalletTransferPreviewQry()

  const { fee, feeCurrency, processingAmount, processingCurrency, walletAmount, walletCurrency, trustedRecipient } =
    transferPreview || {}

  useEffect(() => {
    if (responseContainsStaffOverLimitError(transferError)) {
      modalShow({
        options: { size: 'auto' },
        content: <CreateExpenseRequestModal formValues={formValues} />
      })
    }
    // eslint-disable-next-line
  }, [transferError])

  const {
    country: recipientCountryCode,
    holderName: recipientName,
    name,
    iban: recipientIban,

    bankCode,
    bankName,
    branchCode,
    branchName,
    accountNumber,

    bicSwift: recipientSwift
  } = selectedRecipient || {}

  const { name: accountName } = selectedAccount || {}

  return (
    <div className='send-money-container'>
      <div className='send-money-inside-container amount-container mb-2'>
        <div className='main-amount-label'>{tr('FRONTEND.WITHDRAW.BANK_TRANSFER.PREVIEW.AMOUNT', 'Amount')}</div>
        <div className='main-amount-value'>
          <span data-test='amount'>
            {formatFloat(walletAmount, getCurrencyPrecision(walletCurrency))}{' '}
            <span className='main-amount-currency'>{walletCurrency}</span>
          </span>
        </div>

        {fee > 0 && (
          <SendMoneyAmountRow
            label={tr('FRONTEND.WITHDRAW.BANK_TRANSFER.PREVIEW.FEE', 'Fee')}
            value={
              <span>
                {formatFloat(fee, getCurrencyPrecision(feeCurrency))}{' '}
                <span className='row-amount-currency'>{feeCurrency}</span>
              </span>
            }
            data-test='fee-amount'
          />
        )}

        <SendMoneyAmountRow
          label={tr('FRONTEND.WITHDRAW.BANK_TRANSFER.PREVIEW.NET_AMOUNT', 'Net amount')}
          value={
            <span>
              {formatFloat(processingAmount, getCurrencyPrecision(processingCurrency))}{' '}
              <span className='row-amount-currency'>{processingCurrency}</span>
            </span>
          }
          data-test='net-amount'
        />
      </div>

      <div className='send-money-inside-container send-money-details'>
        <SendMoneyValueLabel
          label={tr('FRONTEND.BANKING.SEND_MONEY.FROM_ACCOUNT', 'From account')}
          value={accountName}
          dataTest='account-name'
        />
        <SendMoneyValueLabel
          label={tr('FRONTEND.WITHDRAW.BANK_TRANSFER.PREVIEW.TO', 'To')}
          value={recipientName || name}
          dataTest='recipient-name'
        />
        <SendMoneyValueLabel
          label={tr('FRONTEND.WITHDRAW.BANK_TRANSFER.PREVIEW.COUNTRY', 'Country')}
          value={translateCountryName(recipientCountryCode)}
          dataTest='country'
        />
        <SendMoneyValueLabel
          label={tr('FRONTEND.WITHDRAW.BANK_TRANSFER.PREVIEW.IBAN', 'IBAN')}
          value={recipientIban}
          dataTest='iban'
        />
        <SendMoneyValueLabel
          label={tr('FRONTEND.WITHDRAW.BANK_TRANSFER.PREVIEW.BANK_CODE', 'Bank code')}
          value={bankCode}
          dataTest='bank-code'
        />
        <SendMoneyValueLabel
          label={tr('FRONTEND.WITHDRAW.BANK_TRANSFER.PREVIEW.BANK_NAME', 'Bank name')}
          value={bankName}
          dataTest='bank-name'
        />
        <SendMoneyValueLabel
          label={tr('FRONTEND.WITHDRAW.BANK_TRANSFER.PREVIEW.BRANCH_CODE', 'Branch code')}
          value={branchCode}
          dataTest='branch-code'
        />
        <SendMoneyValueLabel
          label={tr('FRONTEND.WITHDRAW.BANK_TRANSFER.PREVIEW.BRANCH_NAME', 'Branch name')}
          value={branchName}
          dataTest='branch-name'
        />
        <SendMoneyValueLabel
          label={tr('FRONTEND.WITHDRAW.BANK_TRANSFER.PREVIEW.ACCOUNT_NUMBER', 'Account number')}
          value={accountNumber}
          dataTest='account-number'
        />
        <SendMoneyValueLabel
          label={tr('FRONTEND.WITHDRAW.BANK_TRANSFER.PREVIEW.SWIFT', 'SWIFT')}
          value={recipientSwift}
          dataTest='swift'
        />
        <SendMoneyValueLabel
          label={tr('FRONTEND.WITHDRAW.BANK_TRANSFER.PREVIEW.REFERENCE', 'Reference')}
          value={formValues.getValue('reference')}
          dataTest='reference'
        />
      </div>

      <TrustedRecipientCheckbox formValues={formValues} trustedRecipient={trustedRecipient} />

      <div className='mb-2' />

      <ErrorDisplay error={transferError} />

      <div className='preview-actions'>
        <CoreButton
          text={tr('FRONTEND.WITHDRAW.BANK_TRANSFER.PREVIEW.BUTTON_SEND', 'Send')}
          onClick={onSubmit}
          isLoading={transferFetching}
          className='mb-1'
          data-test='button-send'
          size='large'
          fullWidth
        />
        <CoreButton
          text={tr('FRONTEND.BUTTONS.CANCEL', 'Cancel')}
          variation='tertiary'
          onClick={onCancel}
          disabled={transferFetching}
          data-test='button-cancel'
          size='large'
        />
      </div>
    </div>
  )
}
