import { Dispatch, FC, SetStateAction, useEffect, useMemo, useRef, useState } from 'react'

import cn from 'classnames'

import { AccountBalanceOut, PaymentMethodEnum, PaymentOption, PaymentOptionResponse } from 'mmfintech-commons-types'
import { checkSingleValue, formatFloat, getCurrencySymbol, isValidFunction, tr } from 'mmfintech-commons'
import { useCurrencies } from 'mmfintech-backend-api'

import { useDebounce } from '@utils'

import { CoreSelect } from '../CoreSelect'

import PlusIcon from '@images/icons/transaction-type-plus-icon.svg?react'
import SelectAccountImage from '@images/account-select.png'

import './corePaymentInput.scss'

interface ICorePaymentInputProps {
  value?: string
  name?: string
  selectedOption?: AccountBalanceOut | { value: string; label: string }
  setSelectedAccount?: Dispatch<SetStateAction<AccountBalanceOut>> | ((arg: AccountBalanceOut) => void)
  onChange?: any
  paymentOption?: PaymentOption | PaymentOptionResponse
  error?: string
  setError?: (arg: any) => void
  dataTest: string
  options?: AccountBalanceOut[] | { value: string; label: string }[]
  disableAmountChange?: boolean
  disableMaxAmount?: boolean
  disableMinAmount?: boolean
  hideAmount?: boolean
  required?: boolean
  isLoading?: boolean
  hint?: string
  minInputAmount?: number
  maxInputAmount?: number
  enableMinMaxLabel?: boolean
  hideCurrencySymbol?: boolean
  type?: 'account' | 'currency' | 'label'
  enableAccountCreation?: boolean
  label?: string
  defaultCurrency?: string
  extraOptions?: any[] | null
  currencyOptions?: string[]
  isFocusInputOnClick?: boolean
  enableMaxAmountButton?: boolean
  currencyCodeOverride?: string
}

export const CorePaymentInput: FC<ICorePaymentInputProps> = ({
  value,
  name,
  options,
  selectedOption,
  onChange,
  paymentOption,
  error,
  setError,
  dataTest,
  setSelectedAccount,
  hint,
  minInputAmount,
  maxInputAmount,
  hideAmount = false,
  disableMaxAmount = false,
  disableMinAmount = false,
  required = false,
  disableAmountChange = false,
  isLoading = false,
  hideCurrencySymbol = false,
  type = 'account',
  enableAccountCreation = false,
  enableMaxAmountButton = false,
  label,
  defaultCurrency,
  extraOptions = null,
  currencyOptions,
  // focus input when click on the  main div wrapper
  isFocusInputOnClick = false,
  currencyCodeOverride
}) => {
  const [isValueChanged, setIsValueChanged] = useState(false)
  const currencyCode: string = currencyCodeOverride
    ? currencyCodeOverride
    : selectedOption
    ? type == 'account' && 'currencyCode' in selectedOption
      ? selectedOption?.currencyCode
      : 'value' in selectedOption && selectedOption?.value
    : ''
  const { getCurrencyPrecision } = useCurrencies()
  const decimals = getCurrencyPrecision(currencyCode)
  const inputRef = useRef<HTMLInputElement>()

  const isCryptoPaymentMethod = paymentOption?.paymentMethod === PaymentMethodEnum.CRYPTO || hideCurrencySymbol
  const { minAmount: paymentMinAmount, maxAmount: paymentMaxAmount } = paymentOption || {}
  const minAmount = minInputAmount || paymentMinAmount
  const maxAmount = paymentMaxAmount || maxInputAmount

  const handleCheckMinMax = useDebounce(() => {
    setIsValueChanged(true)
  }, 500)

  const minMaxError = useMemo(() => {
    setIsValueChanged(false)
    return {
      isMaxError: maxAmount && value ? maxAmount < Number(value) : false,
      isMinError: minAmount && value ? minAmount > Number(value) : false
    }
  }, [isValueChanged])

  useEffect(() => {
    handleCheckMinMax()
  }, [value, minAmount, maxAmount])

  const handleAmountChange = event => {
    if (isValidFunction(onChange)) {
      const val = checkSingleValue(event.target.value, {
        validation: 'float',
        decimals: paymentOption?.currency
      }).replace(/^0+/g, '0')

      name ? onChange(name, val) : onChange(val)
      event.target.value = val // replace inner input state with the validated one
      setError && setError(null)
    }
  }

  const handleInsertFullMaxAmount = () => {
    if (isValidFunction(onChange)) {
      name ? onChange(name, maxAmount) : onChange(maxAmount)
      setError && setError(null)
    }
  }

  return (
    <div className='core-payment-input-container'>
      <div
        key={name}
        className={cn('core-payment-input-wrapper', {
          error: Boolean(error) || minMaxError?.isMinError || minMaxError?.isMaxError,
          'no-amount': hideAmount
        })}
        data-test={dataTest}
        onClick={() => isFocusInputOnClick && inputRef?.current?.focus()}>
        {type !== 'label' ? (
          <div className='core-payment-select-wrapper'>
            <CoreSelect
              data-test='all-accounts'
              LeftIcon={<img src={SelectAccountImage} width={30} alt='' />}
              type={type}
              label={
                isLoading
                  ? tr('FRONTEND.MONEY_INPUT.LOADING', 'Loading ...')
                  : type === 'account'
                  ? tr('FRONTEND.MONEY_INPUT.SELECT_ACCOUNT', 'Select account')
                  : tr('FRONTEND.MONEY_INPUT.SELECT_CURRENCY', 'Select currency')
              }
              options={options}
              value={selectedOption}
              onChange={(_, selectedOption) => setSelectedAccount(selectedOption)}
              isAccountsLoading={isLoading}
              enableAccountCreation={enableAccountCreation}
              extraOptions={extraOptions}
              currencyOptions={currencyOptions}
            />
          </div>
        ) : (
          <div className='core-payment-select-wrapper label'>
            <span>{label}</span>
          </div>
        )}
        {!hideAmount && (
          <div className={cn('payment-input-container', { limits: maxAmount > 0 || minAmount > 0 })}>
            <div className='input-inner'>
              <>
                {/* {!isCryptoPaymentMethod && (
                  <h3 data-test='currencyCode'>{getCurrencySymbol(currencyCode || defaultCurrency)}</h3>
                )} */}

                <div className='testing'>
                  <div className='inline-editor'>
                    <div className='inline-editor'>
                      <label htmlFor='value' defaultValue={value}>
                        {value}
                      </label>
                    </div>
                    {!disableAmountChange && (
                      <input
                        ref={inputRef}
                        type='text'
                        id='value'
                        name='value'
                        onChange={event => {
                          handleAmountChange(event)
                        }}
                        className='inline-editor__control'
                        value={value}
                        autoFocus
                        required={required}
                        autoComplete='off'
                      />
                    )}
                  </div>
                </div>

                {!isCryptoPaymentMethod && (
                  <span data-test='currencyCode' className='currency-code'>
                    {currencyCode || defaultCurrency}
                  </span>
                )}
              </>
            </div>

            {maxAmount > 0 && !disableMaxAmount && enableMaxAmountButton && (
              <div
                className='add-max-button'
                onClick={() => {
                  enableMaxAmountButton && handleInsertFullMaxAmount()
                }}>
                <PlusIcon />
                <span data-test='value-boundary'>
                  <div key={'max' + maxAmount}>
                    <span className='title'>{tr('FRONTEND.MONEY_INPUT.LABEL_ADD_MAX', 'Add max')}</span>
                  </div>
                </span>
              </div>
            )}
          </div>
        )}
      </div>
      {hint && (
        <div className={cn('core-payment-hint-wrapper')}>
          <span>{hint}</span>
        </div>
      )}
      {error && (
        <div className={cn('core-payment-hint-wrapper', { error: error })}>
          <span>{error}</span>
        </div>
      )}

      <div className='core-payment-limits-wrapper'>
        {minAmount > 0 && !disableMinAmount && (
          <span
            data-test='value-boundary'
            className={cn('value-boundary', { 'min-max-error': minMaxError?.isMinError })}>
            <div key={'min' + minAmount}>
              <span className='title'>{tr('FRONTEND.MONEY_INPUT.LABEL_MIN', 'Min')}</span>
              <span className='content'>
                {!isCryptoPaymentMethod && getCurrencySymbol(currencyCode)} {formatFloat(minAmount, decimals)}
              </span>
            </div>
          </span>
        )}
        {maxAmount > 0 && !disableMaxAmount && (
          <span
            data-test='value-boundary'
            className={cn('value-boundary', { 'min-max-error': minMaxError?.isMaxError })}>
            <div key={'max' + maxAmount}>
              <span className='title'>
                {tr('FRONTEND.MONEY_INPUT.LABEL_MAX_AVAILABLE', 'Max available before fees')}
              </span>
              <span className='content'>
                {!isCryptoPaymentMethod && getCurrencySymbol(currencyCode)} {formatFloat(maxAmount, decimals)}
              </span>
            </div>
          </span>
        )}
      </div>
    </div>
  )
}
