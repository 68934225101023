import { useState } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import styled from 'styled-components'

import { paths } from 'mmfintech-backend-api'

import ExpensesDashboard from '@views/dashboard/ExpensesDashboard'
import CounterpartyDashboard from '@views/counterparty/CounterpartyDashboard'

import { ReportsWrapper } from '../dashboard/ExpensesDashboard/ExpensesDashboard.styled'
import { NavigationSwitch } from '@views/expenseManagement/components'

export const ExpenseManagementWrapper = () => {
  const [selectedView, setSelectedView] = useState<string>('reports')

  return (
    <ExpenseManagementContainer>
      <div>
        <NavigationSwitch selected={selectedView} setSelected={setSelectedView} />

        <Switch>
          <Route path={paths.expenseManagement.reports()} exact component={ExpensesDashboard} />
          <Route path={paths.expenseManagement.counterParty()} exact component={CounterpartyDashboard} />
          <Redirect to={paths.expenseManagement.reports()} />
        </Switch>
      </div>
    </ExpenseManagementContainer>
  )
}

const ExpenseManagementContainer = styled(ReportsWrapper)`
  padding: 0;
`
