import {
  getOnboardingStatusName,
  isMerchantIndividual,
  selectCurrentUserRole,
  useAppSelector,
  useGetMerchantQuery
} from 'mmfintech-backend-api'
import { tr, translateCountryName } from 'mmfintech-commons'
import { LabelValue } from 'mmfintech-portal-commons'

import { CoreLoader, MerchantLogo } from '@components'
import settings from '@settings'
import { Tiers } from './Tiers'

import './profile.scss'
import { MerchantAccountTypeEnum, OnboardingStatusEnum } from 'mmfintech-commons-types'

export const Profile = () => {
  const { data: merchant } = useGetMerchantQuery()
  const {
    accountType,
    onboardingStatus,
    address,
    name: merchantName,
    documentId,
    tradingName,
    registrationNumber,
    primaryCurrency
  } = merchant || {}
  const customerRole = useAppSelector(selectCurrentUserRole)

  const { countryCode, addressLine1, addressLine2, city, region, postalCode } = address || {}

  const isIndividual = isMerchantIndividual(merchant)

  return (
    <div className='profile-wrapper'>
      <div className='profile-container'>
        <div className='avatar-container'>
          <div className='upload-logo-wrap'>
            <MerchantLogo merchantName={merchantName} />
            <div className='logo-info'>{merchantName}</div>
            <div className='role'>{customerRole?.toLowerCase()}</div>
          </div>
        </div>
        <div className='profile-content'>
          <p className='profile-title'>{tr('FRONTEND.PROFILE.TITLE', 'Profile details')}</p>
          {merchant ? (
            <>
              <div className='profile-details'>
                <LabelValue
                  label={
                    isIndividual
                      ? tr('FRONTEND.PROFILE.FULL_NAME', 'Full Name')
                      : tr('FRONTEND.PROFILE.COMPANY_NAME', 'Company Name')
                  }
                  value={merchantName}
                  dataTest='merchant-name'
                />
                <LabelValue
                  label={tr('FRONTEND.PROFILE.TRADING_NAME', 'Trading name')}
                  value={tradingName}
                  dataTest='trading-name'
                />
                <LabelValue
                  label={tr('FRONTEND.PROFILE.REG_NUMBER', 'Registration №')}
                  value={registrationNumber}
                  dataTest='registration-number'
                />
                <LabelValue
                  label={
                    isIndividual
                      ? tr('FRONTEND.PROFILE.NATIONAL_ID', 'National ID')
                      : tr('FRONTEND.PROFILE.VAT_NUMBER', 'VAT Number')
                  }
                  value={documentId}
                  dataTest='national-id'
                />
                <LabelValue
                  label={tr('FRONTEND.PROFILE.COUNTRY', 'Country')}
                  value={translateCountryName(countryCode)}
                  dataTest='country'
                />
                <LabelValue label={tr('FRONTEND.PROFILE.CITY', 'City')} value={city} dataTest='city' />
                <LabelValue
                  label={tr('FRONTEND.PROFILE.POSTAL_CODE', 'Postal code')}
                  value={postalCode}
                  dataTest='postal-code'
                />
                <LabelValue label={tr('FRONTEND.PROFILE.REGION', 'Region')} value={region} dataTest='region' />
                <LabelValue
                  label={tr('FRONTEND.PROFILE.ADDRESS', 'Address')}
                  value={addressLine1}
                  dataTest='address-line-1'
                />
                <LabelValue
                  label={tr('FRONTEND.PROFILE.ADDRESS_2', 'Address 2')}
                  value={addressLine2}
                  dataTest='address-line-2'
                />
                {!isIndividual && (
                  <LabelValue
                    label={tr('FRONTEND.PROFILE.VERIFICATION_STATUS', 'Verification status')}
                    value={getOnboardingStatusName(
                      accountType === MerchantAccountTypeEnum.PROSPECT
                        ? onboardingStatus
                        : OnboardingStatusEnum.APPROVED
                    )}
                    dataTest='verification-status'
                  />
                )}
                <LabelValue
                  label={tr('FRONTEND.PROFILE.PRIMARY_CURRENCY', 'Primary currency')}
                  value={primaryCurrency}
                  dataTest='primary-currency'
                />
              </div>
              <Tiers />
              <ContactSupport />
            </>
          ) : (
            <CoreLoader />
          )}
        </div>
      </div>
    </div>
  )
}

const ContactSupport = () => (
  <div className='contact-support-wrapper'>
    <span className='contact'>
      <span>
        {tr('FRONTEND.PROFILE.CONTACT.PLEASE_CONTACT', 'Please contact our')}{' '}
        <a href={settings.customerServiceUrl} rel='noopener noreferrer' target='_blank'>
          {tr('FRONTEND.PROFILE.CONTACT.CUSTOMER_SERVICE', 'Customer Service')}
        </a>{' '}
        {tr('FRONTEND.PROFILE.CONTACT.UPDATE_DETAILS', 'to update your business details.')}
      </span>
    </span>
  </div>
)
