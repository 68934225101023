import { useContext, useEffect } from 'react'

import settings from '@settings'

import { configuration } from 'mmfintech-backend-api'
import { GlobalContext, tr } from 'mmfintech-commons'

import { CoreButton } from '@components'
import { CookiePolicy } from './CookiePolicy'
import { ManageCookies } from './ManageCookies'

import CookieIcon from '@images/icons/cookies-icon.svg?react'

import './CookieConsent.scss'

export const CookieConsent = ({ setCookie, cookie }) => {
  const { modalShow } = useContext(GlobalContext)

  const handleManageCookieConsent = () => {
    modalShow({
      options: { size: 'medium', hideCloseIcon: true, closeOnClickOutside: false },
      content: <ManageCookies setCookie={setCookie} />
    })
  }

  useEffect(() => {
    if (!cookie) {
      modalShow({
        options: { size: 'medium', hideCloseIcon: true, closeOnClickOutside: false },
        content: <CookiePolicy setCookie={setCookie} />
      })
    }
  }, [cookie])

  return (
    <div className='cookies-consent-wrapper'>
      <div className='top-part'>
        <div className='top-content'>
          <div className='cookie-icon'>
            <CookieIcon />
          </div>
          <div className='cookie-description'>
            <h6>{tr('FRONTEND.COOKIE_CONSENT.TITLE', 'Cookies Policy')}</h6>
            <span>
              {tr(
                'FRONTEND.COOKIE_CONSENT.PERSONALISED_XP',
                'We use cookies to personalize your experience on Kingdom Bank portal. They are all about to personalize content and ads, provide social media features and to analyze our traffic.'
              )}
            </span>
          </div>
        </div>

        <div className='top-buttons'>
          <CoreButton
            type='button'
            variation='primary'
            className='btn-accept'
            size='large'
            text={tr('FRONTEND.COOKIE_CONSENT.ACCEPT_ALL', 'Accept All')}
            data-test='button-accept-all'
            onClick={() => {
              setCookie(
                'cookie.consent',
                JSON.stringify({
                  necessaryCookies: true,
                  optionalCookies: true
                }),
                {
                  days: 365,
                  maxAge: 31536000,
                  domain: configuration.isLocal() ? null : settings.cookieDomain
                }
              )
            }}
          />
          <CoreButton
            type='button'
            variation='tertiary'
            className='btn-manage'
            size='large'
            text={tr('FRONTEND.COOKIE_CONSENT.MANAGE_COOKIES', 'Manage Cookies')}
            onClick={handleManageCookieConsent}
            data-test='button-manage-cookies'
          />
        </div>
      </div>
    </div>
  )
}
